import { EnhancedStore, StateFromReducersMapObject, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import aiBotReducer from '../domains/aiBot/aiBotSlice';
import authReducer from '../domains/auth/authSlice';
import autosaveReducer from '../domains/autoSave/autosaveSlice';
import collapsibleSidebarReducer from '../domains/collapsibleSidebar/collapsibleSidebarSlice';
import curriculumModalReducer from '../domains/curriculumModal/curriculumModalSlice';
import curriculumsReducer from '../domains/curriculums/curriculumsSlice';
import delegationPlannerReducer from '../domains/delegationPlanner/delegationPlannerSlice';
import documentUploadReducer from '../domains/documentUpload/documentUploadSlice';
import modalsReducer from '../domains/modalsSlice/modalsSlice';
import overlaysReducer from '../domains/overlaysSlice/overlaysSlice';
import reportsReducer from '../domains/reports/reportsSlice';
import responsibilityDetailsModalReducer from '../domains/responsibilityDetailsModal/responsibilityDetailsModalSlice';
import searchResultsReducer from '../domains/searchResults/searchResultsSlice';
import slideoutReducer from '../domains/slideOut/slideoutSlice';
import surveyConsumeReducer from '../domains/surveyConsume/surveyConsumeSlice';
import { axiosApi } from '../services/axiosService';
import { embedlyApi } from '../services/embedlyService';
import { giphyApi } from '../services/gifApis/giphyService';
import { pixabayApi } from '../services/pixabayApi/pixabayService';
import { publicConfigApi } from '../services/publicConfigService';
import { trainualApi } from '../services/trainualService';

export const reducer = {
  [trainualApi.reducerPath]: trainualApi.reducer,
  [axiosApi.reducerPath]: axiosApi.reducer,
  [embedlyApi.reducerPath]: embedlyApi.reducer,
  [giphyApi.reducerPath]: giphyApi.reducer,
  [pixabayApi.reducerPath]: pixabayApi.reducer,
  [publicConfigApi.reducerPath]: publicConfigApi.reducer,
  auth: authReducer,
  curriculums: curriculumsReducer,
  collapsibleSidebar: collapsibleSidebarReducer,
  reports: reportsReducer,
  slideout: slideoutReducer,
  documentUpload: documentUploadReducer,
  curriculumModal: curriculumModalReducer,
  modals: modalsReducer,
  overlays: overlaysReducer,
  surveyConsume: surveyConsumeReducer,
  autosave: autosaveReducer,
  delegationPlanner: delegationPlannerReducer,
  detailedResponsibilityModal: responsibilityDetailsModalReducer,
  aiBot: aiBotReducer,
  searchResults: searchResultsReducer,
};

export type RootState = StateFromReducersMapObject<typeof reducer>;

interface PreloadedState {
  auth: {
    slug: string;
    unauthorizedRedirectInProgress: boolean;
    noResourceRedirectInProgress: boolean;
    forbiddenRedirectInProgress: boolean;
  };
}

export let store: EnhancedStore;
const initStore = (preloadedState: PreloadedState) => {
  if (store) return store;

  store = configureStore({
    reducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(
        trainualApi.middleware,
        embedlyApi.middleware,
        giphyApi.middleware,
        axiosApi.middleware,
        pixabayApi.middleware
      );
    },
    preloadedState,
  });

  setupListeners(store.dispatch);

  return store;
};

type Store = ReturnType<typeof initStore>;
export type AppDispatch = Store['dispatch'];

export default initStore;
