import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/dist/query/react';

import {
  beginForbiddenRedirect,
  beginNoResourceRedirect,
  beginUnauthorizedRedirect,
} from '../domains/auth/authSlice';
import { RootState } from '../stores/store';
import { fetchData } from './helpers';

type RedirectOptions = {
  redirectUnauthorized?: boolean;
  redirectNotFound?: boolean;
  maxRetries?: number;
};

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  RedirectOptions
> = retry(
  async (args, api, extraOptions: RedirectOptions) => {
    const rootState = api.getState() as RootState;
    const accountSlug = rootState.auth.slug;
    if (!accountSlug) throw new Error('Account Slug is not defined in state!');

    const urlEnd = typeof args === 'string' ? args : args.url;
    const adjustedUrl = `${accountSlug}/${urlEnd}`;
    const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
    const response = await fetchData()(adjustedArgs, api, extraOptions);

    const { redirectUnauthorized = true, redirectNotFound = true } = extraOptions || {};

    if (
      (redirectUnauthorized && response.status === 401) ||
      (response?.error &&
        'originalStatus' in response.error &&
        response.error.originalStatus === 401)
    ) {
      api.dispatch(beginUnauthorizedRedirect());
    }

    if (redirectUnauthorized && response.status === 403) {
      api.dispatch(beginForbiddenRedirect());
    }

    if (redirectNotFound && response.status === 404) {
      api.dispatch(beginNoResourceRedirect());
    }

    return response;
  },
  { maxRetries: 0 }
);

export default dynamicBaseQuery;
