import Heading from '@tiptap/extension-heading';

const HEADING_BRAND_STYLE_REGEX = /brand-style-h(?<level>\d)/;

const parseHeadingAttributes = (node: HTMLElement) => {
  // Check for specific classes in child elements and skip processing if found
  if (node.querySelector('span.fr-video') || node.querySelector('a.fr-file')) {
    return false;
  }

  // There are times when an image is nested within p and div tags with a brand-style class
  // This ensures the image is displayed and the text is rendered (not as a heading)
  if (node.querySelector('img')) {
    return false;
  }

  const classHeadingMatch = node?.className?.match(HEADING_BRAND_STYLE_REGEX);

  if (classHeadingMatch?.groups?.level) {
    return {
      level: parseInt(classHeadingMatch.groups.level),
    };
  }

  return false;
};

export default Heading.extend({
  parseHTML() {
    const parentRules = this.parent?.();
    return [
      ...(parentRules as []),
      // Here we are adding support for header tags in the format of <p/div class='brand-style-h#'...
      {
        tag: 'p',
        priority: 100,
        getAttrs: parseHeadingAttributes,
      },
      {
        tag: 'div',
        priority: 100,
        getAttrs: parseHeadingAttributes,
      },
    ];
  },
});
