import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { fontMd3, fontSm5 } from '../../../styled/TypeSystem';
import Link from '../../Link';
import ThemedImage from '../../ThemedImage';
import { ActionLocation, NoResultsProps } from './types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHeader = styled.h4(
  ({ theme: { constants, vars } }) => css`
    margin-top: 0;
    margin-bottom: ${constants.spacerSm3};
    font-weight: ${constants.fontBold};
    color: ${vars.textDefault};
    ${fontMd3};
  `
);

const NoResultsWrapper = styled.div<{
  showBackground: boolean;
  showBorder: boolean;
  showBoxShadow: boolean;
  minHeight: string;
  width: string;
}>(
  ({
    theme: { constants, vars },
    showBackground,
    showBorder,
    showBoxShadow,
    minHeight,
    width,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${showBackground ? vars.foundationSurface1 : 'transparent'};
    border: ${showBorder ? `${constants.borderWidthSm} solid ${vars.borderSurface1}` : 'none'};
    box-shadow: ${showBoxShadow ? vars.shadowTopSmall : 'none'};
    border-radius: ${constants.borderRadiusMd};
    margin-top: ${constants.spacerMd3};
    min-height: ${minHeight};
    width: ${width};
    text-align: center;
  `
);

const Wrapper = styled.div`
  width: 100%;
`;

const SubheaderWrapper = styled.div<{ subheaderMargin: string; actionLocation: ActionLocation }>(
  ({ theme: { constants, vars }, subheaderMargin, actionLocation }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${actionLocation === 'inline' ? 'row' : 'column-reverse'};
    gap: ${actionLocation === 'inline' ? constants.spacerSm2 : constants.spacerSm3};
    margin: 0 ${subheaderMargin} ${constants.spacerLg3};
    color: ${vars.textDefault};
    ${fontSm5};
  `
);

const t = initTranslations('empty_state');

const NoResults = (props: NoResultsProps) => {
  const {
    subHeaderCta,
    className = '',
    heading,
    darkImage,
    lightImage,
    minHeight = '28.438rem',
    width = '100%',
    iconWidth = '100%',
    showBackground = true,
    showBorder = true,
    showBoxShadow = false,
    showSubheader = true,
    subheaderMargin = '0.625rem',
    showImage = true,
    subHeaderText,
  } = props;

  return (
    <FlexWrapper>
      <NoResultsWrapper
        className={className}
        minHeight={minHeight}
        showBackground={showBackground}
        showBorder={showBorder}
        showBoxShadow={showBoxShadow}
        width={width}
      >
        <Wrapper className='no-results'>
          {showImage && (
            <ThemedImage
              alt={heading ? heading : t('no_results_found')}
              darkImage={darkImage}
              height='auto'
              lightImage={lightImage}
              width={iconWidth}
            />
          )}
          {heading && <StyledHeader>{heading}</StyledHeader>}
          {showSubheader && (
            <SubheaderWrapper
              actionLocation={subHeaderCta?.location || 'inline'}
              subheaderMargin={subheaderMargin}
            >
              {subHeaderCta && (
                <Link
                  behavesAs='button'
                  className='no-results-cta'
                  onClick={() => {
                    subHeaderCta.action();
                  }}
                  text={subHeaderCta.text}
                />
              )}
              {subHeaderText && subHeaderText}
            </SubheaderWrapper>
          )}
        </Wrapper>
      </NoResultsWrapper>
    </FlexWrapper>
  );
};

export default NoResults;
