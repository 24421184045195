import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';

import { GiphyBaseParams, GiphySearchParams } from '../../../types/GiphyResponse';
import { MultiResponseDownsized } from './types';

export const giphyApi = createApi({
  reducerPath: 'giphyApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.giphy.com/v1/gifs/' }),
  endpoints: (builder) => ({
    getTrending: builder.query<MultiResponseDownsized, GiphyBaseParams>({
      keepUnusedDataFor: 0,
      query: ({ limit = 20, rating = 'pg', api_key }: GiphyBaseParams) => ({
        url: 'trending?',
        params: { limit, rating, api_key },
      }),
    }),
    getSearch: builder.query<MultiResponseDownsized, GiphySearchParams>({
      keepUnusedDataFor: 0,
      query: ({ limit = 20, lang = 'en', rating = 'pg', q, api_key }: GiphySearchParams) => ({
        url: 'search?',
        params: { limit, lang, rating, q, api_key },
      }),
    }),
  }),
});

export const { useGetTrendingQuery } = giphyApi;
