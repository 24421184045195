import React from 'react';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import useBubbleMenuActions from '../../../hooks/useBubbleMenuActions';
import { BubbleButton } from '../../../shared/styles';

const t = initTranslations('editor.embed_options_bar');

type Props = {
  hideOptionsBar: () => void;
};

const CopyNodeButton = ({ hideOptionsBar }: Props) => {
  const { editor, currentAttributes } = useEditorEmbedOptionsContext();
  const actions = useBubbleMenuActions(currentAttributes, editor);

  const handleClick = () => {
    actions.copyNodeToClipboard();
    hideOptionsBar();
  };

  return (
    <BubbleButton onClick={handleClick}>
      <Icon name='copy' size='xs' />
      {t('copy')}
    </BubbleButton>
  );
};

export default CopyNodeButton;
