import { NodeViewProps } from '@tiptap/react';
import React from 'react';

import { ResizePosition } from '../resizable_image/ImageResizeComponent';

const MIN_IMAGE_SIZE = 50;

export const resizeImageHandler = (
  position: ResizePosition,
  updateAttributes: NodeViewProps['updateAttributes'],
  mouseDownEvent: React.MouseEvent<HTMLImageElement>
) => {
  const parent = (mouseDownEvent.target as HTMLElement).closest('.editor-image-wrapper');
  const image = parent?.querySelector('img.editor-image');

  if (!image) return;

  const startSize = { x: image.clientWidth, y: image.clientHeight };
  const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

  function onMouseMove(mouseMoveEvent: MouseEvent) {
    // We need to multiply by 2 below since the image is resizing from the center
    const newWidth =
      position === 'right'
        ? startSize.x + (mouseMoveEvent.pageX - startPosition.x) * 2
        : startSize.x + (startPosition.x - mouseMoveEvent.pageX) * 2;

    updateAttributes({
      width: Math.max(newWidth, MIN_IMAGE_SIZE),
    });
  }
  function onMouseUp() {
    document.body.removeEventListener('mousemove', onMouseMove);
  }

  document.body.addEventListener('mousemove', onMouseMove);
  document.body.addEventListener('mouseup', onMouseUp, { once: true });
};
