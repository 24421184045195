import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';
import IconButton from '../../buttons/IconButton';
import AssistiveText from '../../core/AssistiveText';
import FieldLabel from '../../core/FieldLabel';
import {
  DisabledFieldTooltipProps,
  LabelTooltipProps,
  TooltipProps,
} from '../../core/Labels/SharedLabelTypes';
import Icon from '../../display/icons/Icon';
import { IconSize, IconWeight } from '../../display/icons/Icon/IconTypes';
import Tooltip from '../../display/Tooltip/Tooltip';
import { InputGroup } from '../../input/shared/styles';

type NativeInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type InputProps = Omit<NativeInputProps, 'ref'> & {
  forwardRef?: React.RefObject<HTMLInputElement>;
  errorText?: string | false;
  wrapErrors?: boolean;
};

const sharedIconStyles = css`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const ValidationText = styled.p`
  font-size: 0.8rem;
  margin: 0.3125rem 0 0;
`;

const TooltipTrigger = styled.div`
  width: 100%;
`;

const StyleIcon = styled(Icon)`
  left: 14px;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${sharedIconStyles};
`;

export const ErrorText = styled(ValidationText)<{ wrapErrors?: boolean }>`
  color: ${({ theme: { vars } }) => vars.stateError};
  ${(props) => (props.wrapErrors ? `white-space: pre-line` : '')};
`;

export const Input = styled.input<{
  error?: boolean;
  disabled?: boolean;
  iconName?: IconName;
  iconButton?: IconName;
  showBorder?: boolean;
}>`
  color: ${({ theme: { vars } }) => vars.textDefault};
  opacity: ${(props) => (props.disabled ? '0.5' : '1.0')};
  background-color: ${({ theme: { vars }, disabled }) =>
    disabled ? vars.foundationBase1 : vars.foundationSurface1};
  padding: ${({ iconName, iconButton, theme: { constants } }) => `
    0 ${iconButton ? constants.spacerLg2 : constants.spacerMd2}
    0 ${iconName ? constants.spacerLg2 : constants.spacerMd2}
  `};
  -webkit-box-shadow: 0 0 0 50px ${({ theme: { vars } }) => vars.foundationSurface1} inset;
  box-shadow: 0 0 0 50px ${({ theme: { vars } }) => vars.foundationSurface1} inset;
  caret-color: ${({ theme: { vars } }) => vars.textDefault};
  width: 100%;
  height: ${({ theme: { constants } }) => constants.heightMd};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};

  ${({ showBorder }) =>
    showBorder &&
    css`
      border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
        ${({ theme: { vars } }) => vars.borderDefault};
      border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
    `};

  &:focus {
    outline: none;
    border-color: ${({ theme: { vars }, error }) =>
      error ? vars.stateError : vars.accentPrimaryDefault};
    color: ${({ theme: { vars } }) => vars.textDefault};
    -webkit-box-shadow: 0 0 0 50px ${({ theme: { vars } }) => vars.foundationSurface1} inset;
    box-shadow: 0 0 0 50px ${({ theme: { vars } }) => vars.foundationSurface1} inset;
    caret-color: ${({ theme: { vars } }) => vars.textDefault};
  }
  ${(props) => (props.error ? `border-color: ${props.theme.vars.stateError};` : '')};

  ::-webkit-input-placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  ::-moz-placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  ::-ms-input-placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  ::placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }
  ${fontSm5};
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  width: 41px;
  ${sharedIconStyles};
`;

// DS Override: Color of text
const StyledIconButton = styled(IconButton)`
  color: ${({ theme: { vars } }) => vars.textSubdued} !important;

  svg {
    pointer-events: none;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

type IconProps = {
  iconName?: IconName;
  iconWeight?: IconWeight;
  iconSize?: IconSize;
};

type IconButtonProps =
  | {
      iconButtonAriaLabel: string;
      iconButtonIsDataTip?: boolean;
      iconButtonId?: string;
      iconButtonName: IconName;
      iconButtonOnClick?: () => void;
      iconButtonTooltipId?: string;
      iconButtonTooltipText?: string;
    }
  | {
      iconButtonAriaLabel?: never;
      iconButtonIsDataTip?: never;
      iconButtonId?: never;
      iconButtonName?: never;
      iconButtonOnClick?: never;
      iconButtonTooltipId?: never;
      iconButtonTooltipText?: never;
    };

export type Props = {
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  showBorder?: boolean;
  assistiveText?: string;
} & InputProps &
  IconButtonProps &
  DisabledFieldTooltipProps &
  LabelTooltipProps &
  IconProps;

const InputField = (props: Props) => {
  const {
    errorText,
    iconButtonAriaLabel,
    iconButtonOnClick,
    iconButtonTooltipId,
    iconButtonTooltipText,
    iconName,
    iconWeight = 'regular',
    iconSize = 'xs',
    iconButtonName,
    iconButtonId,
    label,
    iconButtonIsDataTip,
    labelTooltipText,
    labelTooltipId,
    disabledFieldTooltipId,
    disabledFieldTooltipText,
    placeholder,
    showBorder = true,
    assistiveText,
    required = false,
    wrapErrors = false,
    ...standardProps
  } = props;
  const name = standardProps.name || '';
  const id = standardProps.id || name;
  const iconButtonRequiredProps = iconButtonName && iconButtonAriaLabel && iconButtonTooltipText;
  const labelToolTipsProps: TooltipProps =
    labelTooltipText && labelTooltipId
      ? { tooltipText: labelTooltipText, tooltipId: labelTooltipId }
      : {};

  return (
    <InputGroup>
      {label && (
        <FieldLabel {...labelToolTipsProps} htmlFor={id} required={required} text={label} />
      )}
      <InputWrapper>
        {iconName && <StyleIcon name={iconName} size={iconSize} weight={iconWeight} />}
        {props.disabled && disabledFieldTooltipId && disabledFieldTooltipText ? (
          <TooltipTrigger
            data-for={disabledFieldTooltipId}
            data-tip='true'
            id={disabledFieldTooltipId}
          >
            <Tooltip id={disabledFieldTooltipId} text={disabledFieldTooltipText} />
            <Input
              {...standardProps}
              autoComplete='off'
              error={!!errorText}
              iconButton={iconButtonName}
              iconName={iconName}
              placeholder={placeholder}
              ref={props.forwardRef}
              showBorder={showBorder}
            />
          </TooltipTrigger>
        ) : (
          <Input
            {...standardProps}
            autoComplete='off'
            error={!!errorText}
            iconButton={iconButtonName}
            iconName={iconName}
            placeholder={placeholder}
            ref={props.forwardRef}
            showBorder={showBorder}
          />
        )}

        {iconButtonRequiredProps && (
          <IconButtonWrapper>
            <Tooltip id={iconButtonTooltipId} text={iconButtonTooltipText} />
            <StyledIconButton
              ariaLabel={iconButtonAriaLabel}
              dataTip={iconButtonIsDataTip}
              id={iconButtonId}
              name={iconButtonName}
              onClick={iconButtonOnClick}
              tooltipId={iconButtonTooltipId}
              weight='regular'
            />
          </IconButtonWrapper>
        )}
      </InputWrapper>
      {(assistiveText || errorText) && (
        <AssistiveText
          id={`input-${assistiveText ? 'help' : 'error'}-text`}
          text={assistiveText || errorText}
          type={assistiveText ? 'help' : 'error'}
          wrapErrors={wrapErrors}
        />
      )}
    </InputGroup>
  );
};

export default InputField;
