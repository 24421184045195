import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SearchResultStep } from '../../services/resourceApis/searches/types';

interface State {
  semanticSteps: SearchResultStep[];
  isSemanticStepsFetching: boolean;
}

const initialState: State = {
  semanticSteps: [],
  isSemanticStepsFetching: false,
};

const searchResultsSlice = createSlice({
  name: 'searchResultsSlice',
  initialState,
  reducers: {
    setSemanticSteps(state, action: PayloadAction<SearchResultStep[]>) {
      state.semanticSteps = action.payload;
    },

    setIsSemanticStepsFetching(state, action: PayloadAction<boolean>) {
      state.isSemanticStepsFetching = action.payload;
    },
  },
});

export const { setSemanticSteps, setIsSemanticStepsFetching } = searchResultsSlice.actions;
export default searchResultsSlice.reducer;
