import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type Slideout = {
  type: string;
  args?: SlideoutArgs;
};

export type SlideoutArgs = {
  [name: string]: string;
};

interface State {
  stateInitialized: boolean;
  currentSlideout: Slideout | null;
}

const initialState: State = {
  stateInitialized: false,
  currentSlideout: null,
};

const slideoutSlice = createSlice({
  name: 'slideout',
  initialState,
  reducers: {
    openSlideout(state, action: PayloadAction<Slideout | null>) {
      state.currentSlideout = action.payload;
    },
    closeSlideout(state) {
      state.currentSlideout = null;
    },
    setStateInitialized(state) {
      state.stateInitialized = true;
    },
  },
});

export const { openSlideout, closeSlideout, setStateInitialized } = slideoutSlice.actions;
export default slideoutSlice.reducer;
