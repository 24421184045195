import { css } from 'styled-components';

import { ContentStyles } from '../../../types/ContentStyle';
import contentStyles from './ContentStyles';

export default css<{ $contentStyles: ContentStyles }>`
  * {
    font-family: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  tr {
    line-height: 1.5;
  }

  h1 {
    margin: ${({ theme: { constants } }) => `${constants.spacerLg1} 0 ${constants.spacerSm2} 0`};
  }

  h2 {
    margin: ${({ theme: { constants } }) => `${constants.spacerMd2} 0 ${constants.spacerSm2} 0`};
  }

  h3 {
    margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 ${constants.spacerSm2} 0`};
  }

  h4 {
    margin: ${({ theme: { constants } }) => constants.spacerSm2} 0;
  }

  p {
    line-height: 1.6;
    margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  }

  ul,
  ol {
    display: flow-root;

    li {
      line-height: 1.5;
      p {
        margin: 0;
      }
    }
  }

  ${contentStyles};
`;
