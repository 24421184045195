import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: ReactNode;
}

const Portal = ({ children }: Props) => {
  const element = document.getElementById('react-portal');

  if (!element) {
    throw new Error('portal cannot be found');
  }

  return createPortal(children, element);
};

export default Portal;
