import * as yup from 'yup';

import initTranslations from '../../../../lib/initTranslations';
import isUrl from '../../../../lib/isUrl';

const t = initTranslations('editor.validation_errors');

export const getValidationSchema = (customBlankMessage?: string) => {
  return yup.object().shape({
    title: yup
      .string()
      .trim()
      .required(customBlankMessage || t('no_title'))
      .test('max', (val) => {
        const current_chars = val ? val.length : 0;
        if (current_chars > 250) {
          throw new yup.ValidationError(t('too_long', { current_chars }), val, 'title');
        }
        return true;
      }),
  });
};

export const validateHexCodeSchema = yup.object().shape({
  color: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t('color_error')),
});

const CAPTURE_URL_PATTERN = /https:\/\/capture\.[\w-]+\.com\/record\/[\w-]+/g;

export const validateCaptureUrl = (productTerm: string) => {
  return yup.object().shape({
    url: yup
      .string()
      .matches(CAPTURE_URL_PATTERN, t('invalid_trainual_capture_url', { productTerm }))
      .required(t('blank_trainual_capture_url', { productTerm })),
  });
};

const isIframeCodeValid = (value: string | undefined) => {
  if (!value) return false;
  return value.startsWith('<iframe') && value.endsWith('</iframe>');
};

const hasValidSrcAttribute = (value: string | undefined) => {
  if (!value) return false;

  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');
  const iframeElement = doc.querySelector('iframe');

  if (!iframeElement) return false;

  const srcAttribute = iframeElement.getAttribute('src');
  if (!srcAttribute) return false;

  return isUrl(srcAttribute);
};

export const iframeValidationSchema = yup.object().shape({
  embeddedCode: yup
    .string()
    .required(t('missing_iframe_code'))
    .test('is-iframe', t('invalid_iframe'), isIframeCodeValid)
    .test('valid-src', t('missing_src'), hasValidSrcAttribute),
});

export const urlValidationSchema = yup.object({
  link: yup
    .string()
    .required(t('url_required'))
    .test('is-valid-url', t('invalid_url'), (value) => {
      return isUrl(value || '');
    })
    .nullable(),
});
