import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { EditorEmbedOptionsProvider } from '../../../../../contexts/EditorEmbedOptionsContext';
import Icon from '../../../../design_system/display/icons/Icon';
import OutsideClickHandler from '../../../shared/OutsideClickHandler';
import EmbedOptionsBar from '../../components/EmbedOptionsBar/EmbedOptionsBar';
import { BaseThreeDotButtonWrapper } from '../../components/EmbedOptionsBar/ThreeDotButton';
import { prependHttps } from '../../shared/helpers';
import { CustomNodeSelectedState, DragHandle } from '../../shared/styles';
import { resizeImageHandler } from '../helpers/resizeImageHandler';
import { EmbedExtensionName } from '../types';
import Image from './Image';

export type ResizePosition = 'right' | 'left';

const RESIZE_HANDLE_WIDTH = '.4rem';
const RESIZE_HANDLE_HEIGHT = '3rem';
const MIN_IMAGE_WIDTH = '150px';

const ResizeHandle = styled.div<{ position: ResizePosition }>`
  background: transparent;
  cursor: col-resize;
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(${RESIZE_HANDLE_WIDTH} * 3);

  ${({ position }) => {
    if (position === 'left') {
      return css`
        padding-left: ${RESIZE_HANDLE_WIDTH};
        left: -${RESIZE_HANDLE_WIDTH};
      `;
    } else {
      return css`
        padding-right: ${RESIZE_HANDLE_WIDTH};
        right: -${RESIZE_HANDLE_WIDTH};
      `;
    }
  }}

  ::after {
    content: '';
    width: ${RESIZE_HANDLE_WIDTH};
    height: ${RESIZE_HANDLE_HEIGHT};
    opacity: 0.6;
    background: ${({ theme: { vars } }) => vars.foundationSurface1};
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderRadiusXs} solid ${vars.borderSurface2}`};
    border-radius: calc(${RESIZE_HANDLE_WIDTH} / 2);
    position: absolute;
    top: 50%;
    margin-top: calc(${RESIZE_HANDLE_HEIGHT} / 2 * -1);
    ${({ position }) => {
      if (position === 'left') {
        return css`
          right: 0;
        `;
      } else {
        return css`
          left: 0;
        `;
      }
    }}
  }
`;

const ImageContentWrapper = styled.div<{
  imageWidth: string | null;
  isRoundCorners: boolean;
  isEditable: boolean;
}>`
  position: relative;
  display: inline-block;
  line-height: 0;

  &:hover {
    box-shadow: ${({ isEditable }) => (isEditable ? '1px 1px 5px 0 rgba(0, 0, 0, 0.2)' : 'none')};
    border-radius: ${({ theme: { constants }, isRoundCorners }) =>
      isRoundCorners && constants.borderRadiusLg};
  }

  max-width: ${({ imageWidth }) => (imageWidth ? `${imageWidth}px` : '100%')};
  height: auto;
  min-width: ${MIN_IMAGE_WIDTH};

  img {
    min-width: ${MIN_IMAGE_WIDTH};
  }
`;

const CustomNodeViewWrapper = styled(NodeViewWrapper)<{
  $hovering: boolean;
  $focused: boolean;
  $isRoundCorners: boolean;
  selected: boolean;
}>`
  position: relative;
  display: block;
  outline: none;
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid transparent;

  ${DragHandle}, ${ResizeHandle} {
    display: ${({ $hovering }) => ($hovering ? 'block' : 'none')};
  }

  // TODO: https://trainual.atlassian.net/browse/GS5-263 consolidate selected and focused styles into a single style
  ${ImageContentWrapper} {
    ${({ $focused, isRoundCorners, selected }) =>
      $focused &&
      !selected &&
      css`
        outline: auto;
        outline: rgba(0, 112, 243, 0.7) solid 2px;
        border-radius: ${({ theme: { constants } }) =>
          isRoundCorners ? constants.borderRadiusLg : constants.borderRadiusMd};
      `}
  }
`;

const ImageWrapper = styled.div<{ alignment: string | null }>`
  position: relative;
  display: flex;

  ${({ alignment }) => {
    if (alignment === 'left') {
      return 'justify-content: flex-start;';
    } else if (alignment === 'right') {
      return 'justify-content: flex-end;';
    } else {
      return 'justify-content: center;';
    }
  }}
`;

const SelectedState = styled(CustomNodeSelectedState)<{ isRoundCorners: boolean }>`
  border-radius: ${({ theme: { constants }, isRoundCorners }) =>
    isRoundCorners ? constants.borderRadiusLg : 0};
`;

export const ThreeDotButtonWrapper = styled(BaseThreeDotButtonWrapper)<{
  hovering: boolean;
}>`
  display: ${({ optionsBarVisible, hovering }) =>
    optionsBarVisible || hovering ? 'initial' : 'none'};
  box-shadow: ${({ hovering }) => (hovering ? '1px 1px 5px 0 rgba(0, 0, 0, 0.2)' : 'none')};
`;

export type ImageStyles = {
  isBorder: boolean;
  isShadow: boolean;
  isRoundCorners: boolean;
  alt: string | undefined;
};

type Props = {
  editor: NodeViewProps['editor'];
  node: {
    attrs: Pick<
      NodeViewProps['node']['attrs'],
      | 'alignment'
      | 'src'
      | 'width'
      | 'isBorder'
      | 'isShadow'
      | 'isRoundCorners'
      | 'altText'
      | 'link'
    >;
  };
  updateAttributes: NodeViewProps['updateAttributes'];
  deleteNode: NodeViewProps['deleteNode'];
  selected: boolean;
} & ImageStyles;

const ImageResizeComponent = ({ editor, node, updateAttributes, deleteNode, selected }: Props) => {
  const { src, alignment, altText, width, isBorder, isShadow, isRoundCorners, link } = node.attrs;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [hoveringWrapper, setHoveringWrapper] = useState(false);
  const [focusingWrapper, setFocusingWrapper] = useState(false);

  const handleFocusIn = () => setFocusingWrapper(true);
  const handleFocusOut = () => setFocusingWrapper(false);

  useEffect(() => {
    const node = wrapperRef.current;

    const handleClick = (event: MouseEvent) => {
      if (node?.contains(event.target as Node)) {
        setFocusingWrapper(true);
      }
    };

    node?.addEventListener('click', handleClick);
    node?.addEventListener('focusin', handleFocusIn);
    node?.addEventListener('focusout', handleFocusOut);

    return () => {
      node?.removeEventListener('click', handleClick);
      node?.removeEventListener('focusin', handleFocusIn);
      node?.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  const leftResizeHandler = useCallback(
    (mouseMoveEvent: React.MouseEvent<HTMLImageElement>) => {
      resizeImageHandler('left', updateAttributes, mouseMoveEvent);
    },
    [updateAttributes]
  );

  const rightResizeHandler = useCallback(
    (mouseMoveEvent: React.MouseEvent<HTMLImageElement>) => {
      resizeImageHandler('right', updateAttributes, mouseMoveEvent);
    },
    [updateAttributes]
  );

  return (
    <CustomNodeViewWrapper
      $focused={focusingWrapper}
      $hovering={hoveringWrapper}
      $isRoundCorners={isRoundCorners}
      className='editor-image-wrapper'
      draggable
      onMouseLeave={() => setHoveringWrapper(false)}
      onMouseOver={() => setHoveringWrapper(true)}
      ref={wrapperRef}
      selected={selected}
      tabIndex={0}
    >
      <OutsideClickHandler onOutsideClick={() => setFocusingWrapper(false)}>
        <EditorEmbedOptionsProvider
          currentAttributes={{
            ...node.attrs,
            extensionName: EmbedExtensionName.IMAGE,
          }}
          deleteNode={deleteNode}
          editor={editor}
          updateAttributes={updateAttributes}
        >
          <ImageWrapper
            alignment={alignment}
            className={`align-${alignment}`}
            data-testid='editor-image-wrapper'
          >
            <SelectedState
              isRoundCorners={isRoundCorners}
              selected={editor.isEditable && selected}
              stretchToFit
            >
              <ImageContentWrapper
                imageWidth={width}
                isEditable={editor.isEditable}
                isRoundCorners={isRoundCorners}
              >
                <Image
                  alt={altText}
                  isBorder={isBorder}
                  isRoundCorners={isRoundCorners}
                  isShadow={isShadow}
                  link={prependHttps(link)}
                  src={src}
                  width={width}
                />
                {editor.isEditable && (
                  <>
                    <EmbedOptionsBar
                      WrapperComponent={ThreeDotButtonWrapper}
                      items={[
                        'styles',
                        'width',
                        'alignment',
                        'altText',
                        'delete',
                        'copy',
                        'unlink',
                      ]}
                      menuType='image'
                      triggerId={uuidv4()}
                      wrapperProps={{ hovering: hoveringWrapper }}
                    />
                    <ResizeHandle onMouseDown={leftResizeHandler} position='left'></ResizeHandle>
                    <ResizeHandle onMouseDown={rightResizeHandler} position='right'></ResizeHandle>
                    <DragHandle data-drag-handle>
                      <Icon name='grip-vertical' />
                    </DragHandle>
                  </>
                )}
              </ImageContentWrapper>
            </SelectedState>
          </ImageWrapper>
        </EditorEmbedOptionsProvider>
      </OutsideClickHandler>
    </CustomNodeViewWrapper>
  );
};

export default ImageResizeComponent;
