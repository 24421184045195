import React from 'react';
import styled from 'styled-components';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import { EmbedExtensionName } from '../../../tiptap_extensions/types';

const t = initTranslations('editor.embed_options_bar');

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  :hover,
  :active,
  :visited {
    color: ${({ theme: { vars } }) => vars.textDefault} !important;
  }
`;

const TextWrapper = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const OpenLink = () => {
  const { currentAttributes } = useEditorEmbedOptionsContext();

  if (currentAttributes.extensionName === EmbedExtensionName.IMAGE) return <></>;

  const { contentUrl } = currentAttributes;

  if (!contentUrl) return <></>;

  return (
    <StyledLink href={contentUrl} rel='noreferrer' target='_blank'>
      <Icon name='arrow-up-right-from-square' size='xs' />
      <TextWrapper>{t('open')}</TextWrapper>
    </StyledLink>
  );
};

export default OpenLink;
