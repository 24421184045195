import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import Icon from '../../../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import { getChevronIconName } from '../../../../../shared/helpers';

const DropdownButton = styled.button<{ dropdownOpen: boolean }>`
  position: relative;
  ${({ dropdownOpen }) =>
    dropdownOpen &&
    css`
      color: ${({ theme: { vars } }) => `${vars.accentPrimaryDefault} !important`};
      font-weight: ${({ theme: { constants } }) => `${constants.fontRegular} !important`};
    `};
`;

const MENU_WIDTHS: { [key in MenuWidth]: string } = { regular: '7.5rem', wide: '13.5rem' };

const DropdownMenu = styled.ul<{ menuWidth: MenuWidth }>`
  && {
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
    list-style: none;
    width: ${({ menuWidth }) => MENU_WIDTHS[menuWidth]};
    position: absolute;
    top: 2.4rem;
    left: 0;
    padding: 0;
    margin: 0;
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
    overflow: hidden;
    box-shadow: ${({ theme: { vars } }) => vars.shadowCenterSmall};
    z-index: 10;
  }
`;

export const MenuOption = styled.li<{ isActive?: boolean }>`
  && {
    color: ${({ theme: { vars } }) => vars.textDefault};
    display: flex;
    align-items: center;
    padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerMd2}`};
    font-weight: ${({ theme: { constants } }) => constants.fontLight};
    ${fontSm5};

    :hover {
      background-color: ${({ theme: { vars } }) => vars.foundationHover};
    }

    ${({ isActive = false }) =>
      isActive &&
      css`
        color: ${({ theme: { vars } }) => `${vars.accentPrimaryDefault} !important`};
        font-weight: ${({ theme: { constants } }) => constants.fontRegular};
        background-color: ${({ theme: { vars } }) => vars.accentSubdued1};

        :hover {
          background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
        }
      `};
  }
`;

const TextWrapper = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const IconTextWrapper = styled.div<{ iconPosition: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  ${({ iconPosition }) =>
    iconPosition === 'left' &&
    css`
      flex-direction: row-reverse;
    `};
`;

type ChevronProps = {
  dropdownOpen: boolean;
};

type MenuWidth = 'regular' | 'wide';

export type MenuProps = {
  openMenuId: string | null;
  setOpenMenuId: (id: string | null) => void;
};

type Props = {
  buttonText: string;
  iconName?: IconName;
  iconPosition?: 'left' | 'right';
  menuWidth?: MenuWidth;
  options: JSX.Element[];
  id: string;
} & MenuProps;

const ChevronIcon = ({ dropdownOpen }: ChevronProps) => {
  const iconName = getChevronIconName({ isActive: dropdownOpen, initialDirection: 'up' });
  return <Icon name={iconName} size='xs' />;
};

const Dropdown = ({
  buttonText,
  iconName,
  iconPosition = 'right',
  menuWidth = 'regular',
  options,
  openMenuId,
  setOpenMenuId,
  id,
}: Props) => {
  const isDropdownOpen = useMemo(() => id === openMenuId, [id, openMenuId]);
  const handleDropdownClick = useCallback(() => {
    setOpenMenuId(isDropdownOpen ? null : id);
  }, [id, isDropdownOpen, setOpenMenuId]);

  return (
    <DropdownButton dropdownOpen={isDropdownOpen} onClick={handleDropdownClick}>
      <IconTextWrapper iconPosition={iconPosition}>
        <TextWrapper>{buttonText}</TextWrapper>
        {iconName ? (
          <Icon name={iconName} size='xs' />
        ) : (
          <ChevronIcon dropdownOpen={isDropdownOpen} />
        )}
      </IconTextWrapper>
      {isDropdownOpen && (
        <DropdownMenu
          data-testid='open-dropdown-menu'
          id='open-dropdown-menu'
          menuWidth={menuWidth}
        >
          {options}
        </DropdownMenu>
      )}
    </DropdownButton>
  );
};

export default Dropdown;
