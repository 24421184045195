import React from 'react';
import styled from 'styled-components';

import { fontSm4 } from '../../../styled/TypeSystem';
import { ImageType } from '../plugins/Image/imageDataReducer';

const ErrorStateContainer = styled.div`
  display: flex;
  align-items: center;
  height: 15.7rem;
  margin-left: 2.5rem;
`;

const ErrorMessage = styled.p`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;

type Props = {
  imageType: ImageType;
  error: string;
};

const ImageErrorState = ({ imageType, error }: Props) => {
  return (
    <ErrorStateContainer data-testid={`${imageType}-error`}>
      <ErrorMessage>{error}</ErrorMessage>
    </ErrorStateContainer>
  );
};

export default ImageErrorState;
