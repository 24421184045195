import React, { ReactElement, ReactNode, createContext, useContext } from 'react';

import { AccountEditorState } from '../types/AccountState';

type AccountEditorContext = {
  account: AccountEditorState;
};

type AccountEditorProviderProps = {
  children: ReactNode;
} & AccountEditorContext;

const AccountEditorContext = createContext({} as AccountEditorContext);
export const useEditorAccount = () => useContext(AccountEditorContext);

const AccountEditorProvider = ({ account, children }: AccountEditorProviderProps): ReactElement => {
  return (
    <AccountEditorContext.Provider value={{ account }}>{children}</AccountEditorContext.Provider>
  );
};

export { AccountEditorProvider };
