import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

export const secondaryHighlightPlugin = new PluginKey('secondaryHighlightPlugin');

export const SecondaryHighlight = Extension.create({
  name: 'secondaryHighlight',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: secondaryHighlightPlugin,
        state: {
          init: () => DecorationSet.empty,
          apply: (tr, old) => {
            const meta = tr.getMeta(secondaryHighlightPlugin);
            if (meta) {
              const { addDecoration, from, to } = meta;
              if (addDecoration) {
                const deco = Decoration.inline(from, to, {
                  style: `background-color: #d9d9d9;`,
                });
                return DecorationSet.create(tr.doc, [deco]);
              } else {
                return DecorationSet.empty;
              }
            }
            return old.map(tr.mapping, tr.doc);
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
