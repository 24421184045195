import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { PixabayResponse, SearchParams } from './types';

export const pixabayApi = createApi({
  reducerPath: 'pixabayApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://pixabay.com/api' }),
  endpoints: (builder) => ({
    getImages: builder.query<PixabayResponse, SearchParams>({
      query: ({ apiKey, safesearch = true, image_type = 'photo', ...params }) => ({
        url: '/',
        params: { key: apiKey, safesearch, image_type, ...params },
      }),
    }),
  }),
});

export const { useGetImagesQuery } = pixabayApi;
