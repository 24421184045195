import { createSlice } from '@reduxjs/toolkit';

interface State {
  showCurriculumModal: boolean;
}

const initialState: State = {
  showCurriculumModal: false,
};

const curriculumModalSlice = createSlice({
  name: 'curriculumModalSlice',
  initialState,
  reducers: {
    openCurriculumModal(state) {
      state.showCurriculumModal = true;
    },
    closeCurriculumModal(state) {
      state.showCurriculumModal = false;
    },
  },
});

export const { openCurriculumModal, closeCurriculumModal } = curriculumModalSlice.actions;
export default curriculumModalSlice.reducer;
