import React from 'react';
import styled from 'styled-components';

import { fontSm3 } from '../../../styled/TypeSystem';

export interface Props {
  currentCharacters: number;
  maxCharacters: number;
}

const Counter = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontLight};
  cursor: default;
  ${fontSm3};
`;

const CharacterCounter = ({ currentCharacters, maxCharacters }: Props) => {
  return (
    <Counter aria-live='polite'>
      {currentCharacters} / {maxCharacters}
    </Counter>
  );
};

export default CharacterCounter;
