import { createApi } from '@reduxjs/toolkit/query/react';

import dynamicBaseQuery from '../baseQueries/dynamicBaseQuery';

export const trainualApi = createApi({
  reducerPath: 'trainualApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [
    'Account',
    'AssignmentsCurriculums',
    'Board',
    'BoardColumn',
    'BoardColumnResponsibility',
    'ChatLog',
    'Curriculum',
    'CurriculumAssignment',
    'CurriculumElement',
    'DocumentUploads',
    'Following',
    'Group',
    'GroupManager',
    'Integration',
    'Notification',
    'OpenAi',
    'Request',
    'Responsibility',
    'ResponsibilitySpecialization',
    'Step',
    'StepExternalLink',
    'Stripe',
    'Suggestion',
    'Survey',
    'SurveyAttempt',
    'SurveyQuestion',
    'TaskLists',
    'Team',
    'Template',
    'TemplateStep',
    'Topic',
    'User',
    'UserActivities',
    'TrainingPath',
  ],
  keepUnusedDataFor: Number(process.env.DEFAULT_REDUX_CACHE_TIME || '10'),
  endpoints: () => ({}),
});
