import React from 'react';
import styled from 'styled-components';

import P from '../../../text/P';
import { TooltipContentType } from '../types';

const StyledP = styled(P)`
  color: ${({ theme: { vars } }) => vars.textSurface};
`;

const TooltipContent = ({ text, content }: TooltipContentType) => {
  return (
    <>
      {content ? (
        content
      ) : Array.isArray(text) ? (
        <>
          {text.map((t) => (
            <StyledP key={t} text={t} />
          ))}
        </>
      ) : (
        <StyledP text={text} />
      )}
    </>
  );
};

export default TooltipContent;
