import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import { Alignment } from '../../../tiptap_extensions/types';
import { EmbedOptionBarItemProps } from '../EmbedOptionsBar';
import Dropdown, { MenuOption } from './shared/Dropdown';

const MenuOptionWraper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const getAlignmentIcon = (alignment: Alignment) => {
  switch (alignment) {
    case 'left':
      return 'align-left';
    case 'center':
      return 'align-center';
    case 'right':
      return 'align-right';
  }
};

const t = initTranslations('editor.embed_options_bar');

const EMBED_ALIGNMENTS: { alignment: Alignment; text: string }[] = [
  { alignment: 'left', text: t('align.left') },
  { alignment: 'center', text: t('align.center') },
  { alignment: 'right', text: t('align.right') },
];

const DEFAULT_ALIGNMENT: Alignment = 'center';

const AlignmentDropdown = ({ openMenuId, setOpenMenuId }: EmbedOptionBarItemProps) => {
  const {
    updateAttributes,
    currentAttributes: { alignment = DEFAULT_ALIGNMENT },
  } = useEditorEmbedOptionsContext();

  const activeIndex = EMBED_ALIGNMENTS.findIndex(({ alignment: align }) => align === alignment);

  const updateAlignment = useCallback(
    (alignment: string) => {
      updateAttributes({ alignment });
    },
    [updateAttributes]
  );

  const options = EMBED_ALIGNMENTS.map(({ alignment: align, text }) => {
    const isActive = align === alignment;
    return (
      <MenuOption isActive={isActive} key={align} onClick={() => updateAlignment(align)}>
        <MenuOptionWraper>
          <Icon name={getAlignmentIcon(align)} size='xs' />
          {text}
        </MenuOptionWraper>
      </MenuOption>
    );
  });

  return (
    <Dropdown
      buttonText={EMBED_ALIGNMENTS[activeIndex].text}
      iconName={getAlignmentIcon(alignment)}
      iconPosition='left'
      id='alignment-dropdown'
      openMenuId={openMenuId}
      options={options}
      setOpenMenuId={setOpenMenuId}
    />
  );
};

export default AlignmentDropdown;
