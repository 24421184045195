import { useFormik } from 'formik';
import React, { useMemo } from 'react';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import InputField from '../../../../../design_system/Triage/InputField';
import { BubbleButton, BubbleFlyout, BubbleForm } from '../../../shared/styles';
import { EmbedExtensionName } from '../../../tiptap_extensions/types';
import { MenuProps } from './shared/Dropdown';

const t = initTranslations('editor.embed_options_bar.alt_text');

const IMG_ALT_TEXT_MENU_ID = 'alt_menu_id';

const ImageAltText = ({ openMenuId, setOpenMenuId }: MenuProps) => {
  const isAltTextFieldVisible = useMemo(() => IMG_ALT_TEXT_MENU_ID === openMenuId, [openMenuId]);
  const { updateAttributes, currentAttributes } = useEditorEmbedOptionsContext();

  const altText =
    currentAttributes.extensionName === EmbedExtensionName.IMAGE ? currentAttributes.altText : '';

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: { altText },
    onSubmit: ({ altText }) => {
      updateAttributes({ altText });
      setOpenMenuId(null);
    },
  });

  if (currentAttributes.extensionName !== EmbedExtensionName.IMAGE) return <></>;

  return (
    <>
      <BubbleButton
        onClick={() => setOpenMenuId(isAltTextFieldVisible ? null : IMG_ALT_TEXT_MENU_ID)}
      >
        <Icon name='text' size='xs' />
        {t('alt_text')}
      </BubbleButton>
      {isAltTextFieldVisible && (
        <BubbleFlyout>
          <BubbleForm onSubmit={handleSubmit}>
            <InputField
              id='altText'
              name='altText'
              onBlur={() => handleSubmit()}
              onChange={handleChange}
              placeholder={t('placeholder')}
              type='text'
              value={values.altText}
            />
          </BubbleForm>
        </BubbleFlyout>
      )}
    </>
  );
};

export default ImageAltText;
