import { ColorTokens } from 'saguaro';
import styled, { css } from 'styled-components';

import { fontSm4, fontSm5 } from '../../styled/TypeSystem';
import Icon from '../display/icons/Icon';
import { TruncatedText } from '../Triage/TruncatedText';
import { LinkColor, LinkSize, UnderlineBehavior } from './LinkTypes';

const getLinkSize = (size: LinkSize) => {
  switch (size) {
    case 'sm':
      return fontSm4;
    case 'md':
      return fontSm5;
  }
};

export const getIconSize = (size: LinkSize) => {
  switch (size) {
    case 'sm':
      return '2xs';
    case 'md':
      return 'xs';
  }
};

const getLinkColor = (type: LinkColor, vars: ColorTokens) => {
  switch (type) {
    case 'accent':
      return vars.accentPrimaryDefault;
    case 'monochrome':
      return vars.textDefault;
    case 'subdued':
      return vars.textSubdued;
  }
};

const getHoverFocusLinkColor = (type: LinkColor, vars: ColorTokens) => {
  switch (type) {
    case 'accent':
      return vars.accentPrimaryHover;
    case 'monochrome':
      return vars.textHover;
    case 'subdued':
      return vars.textSubdued;
  }
};

export const StyledLink = styled.a<{
  color: LinkColor;
  size: LinkSize;
  disabled: boolean;
  underlineBehavior: UnderlineBehavior;
}>`
  padding: 0;
  border: none;
  background-color: transparent;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ color, theme: { vars }, disabled }) =>
    disabled ? vars.textDisabled : getLinkColor(color, vars)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-decoration: ${({ underlineBehavior }) =>
    underlineBehavior === 'static' ? 'underline' : 'none'};
  text-underline-position: under;
  text-decoration-thickness: ${({ theme: { constants } }) => constants.borderWidthLg} !important;

  &.hover,
  &.focus,
  &:hover,
  &:focus {
    outline: none;
    color: ${({ color, disabled, theme: { vars } }) =>
      disabled ? vars.textDisabled : getHoverFocusLinkColor(color, vars)};
    text-decoration: underline;
  }

  ${({ size }) => getLinkSize(size)};
  ${TruncatedText({})};
`;

const iconStyles = css`
  pointer-events: none;
`;

export const SuffixIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  ${iconStyles};
`;

export const PrefixIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  ${iconStyles};
`;
