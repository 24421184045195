import { ButtonSize } from './iconButtonTypes';

export const getIconButtonSize = (buttonSize: ButtonSize) => {
  switch (buttonSize) {
    case 'md':
      return 'sm';
    case 'lg':
      return 'md';
    default:
      return 'xs';
  }
};
