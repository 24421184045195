import { NodeViewProps } from '@tiptap/react';
import { useCallback } from 'react';

import { NodeAttributes } from '../../../../contexts/EditorEmbedOptionsContext';

const useBubbleMenuActions = (nodeAttrs: NodeAttributes, editor: NodeViewProps['editor']) => {
  const getNodePosition = useCallback(
    (attrs: NodeAttributes): number | null => {
      const { state } = editor.view;
      const { doc } = state;
      let pos: number | null = null;

      doc.descendants((node, position) => {
        if (JSON.stringify(attrs) === JSON.stringify(node.attrs)) {
          pos = position;
        }
      });

      return pos;
    },
    [editor.view]
  );

  const copyNodeToClipboard = useCallback(() => {
    const nodePos = getNodePosition(nodeAttrs);

    if (nodePos === null) {
      console.log('Node position not found');
      return;
    }

    editor.chain().setNodeSelection(nodePos).run();

    document.execCommand('copy');
  }, [editor, getNodePosition, nodeAttrs]);

  return {
    copyNodeToClipboard,
  };
};

export default useBubbleMenuActions;
