import camelcaseKeys, { Options as CamelcaseKeysOptions } from 'camelcase-keys';
import snakecaseKeys, { Options as SnakecaseKeysOptions } from 'snakecase-keys';

export const toCamelCase = <T>(data: T, options?: CamelcaseKeysOptions): T => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return camelcaseKeys<T, CamelcaseKeysOptions>(data, { deep: true, ...options }) as unknown as T;
};

export const toSnakeCase = <T>(data: T, options?: SnakecaseKeysOptions): T => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return snakecaseKeys<T, SnakecaseKeysOptions>(data, { deep: true, ...options }) as unknown as T;
};
