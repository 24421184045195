import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import Icon from '../../display/icons/Icon/Icon';
import { IconWeight } from '../../display/icons/Icon/IconTypes';
import { getIconButtonSize } from './iconButtonSize';
import { getIconButtonStyles } from './iconButtonStyles';
import { ButtonSize } from './iconButtonTypes';

type ButtonType = 'button' | 'submit' | 'reset';

const Clickable = styled.button<{
  disabled: boolean;
  size: ButtonSize;
  iconButtonStyles: { [cssProp: string]: string };
}>`
  background-color: transparent;
  border: none;
  outline: none !important;
  color: ${({ theme, disabled }) => (disabled ? theme.vars.textDisabled : theme.vars.textDefault)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover,
  &:focus {
    color: ${({ theme, disabled }) =>
      disabled ? theme.vars.textDisabled : theme.vars.textDefault};
  }
  ${({ iconButtonStyles }) => iconButtonStyles};
`;

export type ButtonProps = {
  id?: string;
  onClick?: (...args: unknown[]) => void;
  className?: string;
  reference?: React.RefObject<HTMLButtonElement>;
  buttonSize?: ButtonSize;
  disabled?: boolean;
  tooltipId?: string;
  dataTip?: boolean;
  dataEvent?: 'click';
  ariaLabel: string;
  buttonType?: ButtonType;
  dataTestId?: string;
};

type IconProps =
  | {
      name: IconName;
      weight?: IconWeight;
      customIcon?: never;
    }
  | {
      name?: never;
      weight?: never;
      customIcon: React.ReactNode;
    };

export type Props = IconProps & ButtonProps;

const IconButton = (props: Props) => {
  const {
    id,
    onClick,
    className = '',
    reference,
    name,
    weight = 'regular',
    buttonSize = 'md',
    disabled = false,
    dataTip = false,
    tooltipId,
    dataEvent,
    ariaLabel,
    buttonType = 'button',
    customIcon,
    dataTestId,
  } = props;

  const theme = useTheme();
  const iconButtonStyles = getIconButtonStyles(buttonSize, theme);
  const iconButtonSize = getIconButtonSize(buttonSize);

  return (
    <Clickable
      aria-label={ariaLabel}
      className={className}
      data-event={dataEvent}
      data-for={tooltipId}
      data-testid={dataTestId}
      data-tip={dataTip}
      disabled={disabled}
      iconButtonStyles={iconButtonStyles}
      id={id}
      onClick={onClick}
      ref={reference}
      size={buttonSize}
      type={buttonType}
    >
      {name && <Icon name={name} size={iconButtonSize} weight={weight} />}
      {customIcon && customIcon}
    </Clickable>
  );
};

export default IconButton;
