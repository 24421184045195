import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useImageFlyoutContext } from '../../../../../contexts/ImageFlyoutContext';
import initTranslations from '../../../../../lib/initTranslations';
import isUrl from '../../../../../lib/isUrl';
import AssistiveText from '../../../../design_system/core/AssistiveText';
import { EditorInputContainer } from '../../shared/styles';
import ImageFlyoutPreview from './ImageFlyoutPreview';
import ImageForm, { Values } from './ImageForm';

const t = initTranslations('editor.image');

const imageSchema = () => {
  return yup.object().shape({
    url: yup
      .string()
      .trim()
      .required(t('form.errors.required'))
      .test('is-valid-url', t('form.errors.invalid_url'), (url) => !!url && isUrl(url)),
  });
};

const ImageInput = () => {
  const { fetchUrl, imageError, imageFile, imageDataDispatch } = useImageFlyoutContext();

  const initialValues: Values = {
    url: '',
  };

  const onSubmit = async ({ url }: Values) => {
    if (imageFile) imageDataDispatch({ type: 'resetImageData' });

    await fetchUrl(url);
  };

  return (
    <EditorInputContainer>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={imageSchema}>
        <ImageForm />
      </Formik>
      <ImageFlyoutPreview ariaLabel={t('aria_label_media_dropzone')} label={t('preview_label')} />
      {imageError && <AssistiveText id='assistive-text-error' text={imageError} type='error' />}
    </EditorInputContainer>
  );
};

export default ImageInput;
