import React from 'react';
import styled from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';
import Icon from '../../../display/icons/Icon';
import Tooltip from '../../../display/Tooltip/Tooltip';
import { TooltipPositiveCondition } from '../SharedLabelTypes';

const StyledDiv = styled.div`
  cursor: default;
  ${fontSm5};
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  pointer-events: none;
`;

const TooltipHoverIcon = ({ tooltipId, tooltipText }: TooltipPositiveCondition) => {
  return (
    <StyledDiv
      data-for={tooltipId}
      data-tip='true'
      id={tooltipId}
      onClick={(event) => event.preventDefault()}
    >
      <Tooltip id={tooltipId} multiline text={tooltipText} />
      <StyledIcon name='circle-info' size='xs' />
    </StyledDiv>
  );
};

export default TooltipHoverIcon;
