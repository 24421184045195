import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import Loader from '../../../../../design_system/Triage/Loader';
import ImageErrorState from '../../../shared/ImageErrorState';
import {
  AnimatedGradient,
  AnimatedIconContainer,
  ImageColumns,
  ImageContainer,
  LoaderWrapper,
} from '../../../shared/styles';
import EmptyPreview from '../preview_state/EmptyPreview';

type GifData = {
  id: string;
  images: { downsized: { url: string; width: string; height: string } };
};

type Props = {
  isLoading: boolean;
  giphyData: GifData[];
  handleImageClick: (url: string) => void;
  selectedImageUrl: string | null;
  isError: string | false | FetchBaseQueryError | SerializedError;
};

const t = initTranslations('editor.image');

const Gifs = ({ isLoading, giphyData, handleImageClick, selectedImageUrl, isError }: Props) => {
  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size='lg' />
      </LoaderWrapper>
    );
  }

  if (isError) {
    return <ImageErrorState error={t('loading_error', { object: t('gifs') })} imageType='giphy' />;
  }

  if (giphyData.length === 0) {
    return <EmptyPreview />;
  }

  return (
    <ImageContainer>
      <ImageColumns>
        {giphyData.map(
          (
            {
              id,
              images: {
                downsized: { url },
              },
            },
            index
          ) => (
            <AnimatedGradient
              className={selectedImageUrl === url ? 'selected' : ''}
              key={`giphy-id-${id}-${index}`}
              onClick={() => handleImageClick(url)}
            >
              <img
                alt='gif'
                className='chromatic-ignore'
                data-testid='gif-image'
                id={`giphy-id-${id}`}
                key={`giphy-id-${id}-${index}`}
                src={url}
              />
              <AnimatedIconContainer data-testid='selected-gif-icon' id='selected-gif-icon'>
                <Icon name='check' size='2xs' />
              </AnimatedIconContainer>
            </AnimatedGradient>
          )
        )}
      </ImageColumns>
    </ImageContainer>
  );
};

export default Gifs;
