import React from 'react';
import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';
import { TooltipProps } from '../../../core/Labels/SharedLabelTypes';
import Icon from '../../../display/icons/Icon';
import Tooltip from '../../../display/Tooltip/Tooltip';
import Loader from '../../../Triage/Loader';

const ToggleContainer = styled.div<{
  hasSecondaryLabel: boolean;
  labelPosition: 'left' | 'right';
}>`
  display: flex;
  height: ${({ theme: { constants } }) => constants.heightSm};
  align-items: ${({ hasSecondaryLabel }) => (hasSecondaryLabel ? 'flex-start' : 'center')};
  flex-direction: ${({ labelPosition }) => (labelPosition === 'right' ? 'row-reverse' : 'row')};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })<{ disabled: boolean }>`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
  transition: all 0.2s;
  &:checked + label span {
    transform: translateX(100%);
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
    color: ${({ checked, theme: { vars } }) => (checked ? vars.textDefault : vars.textDefault)};
  }
  &:checked + label {
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.vars.foundationBase3 : theme.vars.accentPrimaryDefault};
  }
`;

const StyledCheckbox = styled.label<{ disabled: boolean; checked: boolean }>`
  /* The height should not use our heigh variable since we want this to stay the same size no matter the size of the screen. */
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ disabled, checked, theme: { vars } }) =>
    // We should have accentSubdued1 === '#fff' condition as a fallback for cases when increasing accent color lightness by 38% (which is what we need for accentSubdued1)
    // pushes it beyond the maximum lightness, resulting in white
    disabled || vars.accentSubdued1 === '#fff'
      ? vars.foundationBase3
      : checked
      ? vars.accentPrimaryDefault
      : vars.accentSubdued1};
  border-radius: 1.563rem;
  position: relative;
  transition: background-color 0.2s;
  min-width: 2.75rem;
  height: ${({ theme: { constants } }) => constants.heightXs};

  //This is needed to add focus state only when keyboard is tabbing. It will look to see if focus is for the hidden checkbox. This is needed for A11y.
  ${HiddenCheckbox}:focus-visible + & {
    outline: ${({ theme: { constants } }) => constants.borderWidthLg} solid
      ${({ theme: { vars } }) => vars.focusOutlineColor};
  }
`;

const StyledSpan = styled.span<{ disabled: boolean }>`
  display: flex;
  content: '';
  position: absolute;
  left: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  transition: 0.2s;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
`;

const LabelContainer = styled.div<{ hasSecondaryLabel: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ hasSecondaryLabel, theme: { constants } }) =>
    hasSecondaryLabel && `margin-right: ${constants.spacerLg2}`};
  ${fontSm5};
`;

const PrimaryLabel = styled.label<{ disabled: boolean; hasSecondaryLabel: boolean }>`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${({ hasSecondaryLabel }) =>
    hasSecondaryLabel &&
    css`
      font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
    `}
`;

const SecondaryLabel = styled.label<{ disabled: boolean }>`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const StyledIcon = styled(Icon)<{ disabled?: boolean; checked: boolean }>`
  color: ${({ disabled, checked, theme }) =>
    disabled
      ? theme.vars.borderDisabled
      : checked
      ? theme.vars.accentPrimaryDefault
      : theme.vars.textDefault};
`;

export type Props = {
  id: string;
  name: string;
  checked: boolean;
  handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  primaryLabel?: string;
  secondaryLabel?: string;
  ariaLabel?: string;
  isLoading?: boolean;
  dataTestId?: string;
  labelPosition?: 'left' | 'right';
  checkedIcon?: 'check' | 'signature';
  uncheckedIcon?: 'xmark' | 'signature-slash';
} & TooltipProps;

const Toggle = (props: Props) => {
  const {
    checked,
    id,
    name,
    handleToggle,
    disabled = false,
    primaryLabel,
    secondaryLabel,
    tooltipText,
    tooltipId,
    ariaLabel,
    className = '',
    isLoading = false,
    dataTestId,
    labelPosition = 'left',
    checkedIcon = 'check',
    uncheckedIcon = 'xmark',
  } = props;

  //this is needed to optionally spread the tooltip props based on if they are passed.
  const tooltipProps = tooltipId && tooltipText ? { 'data-for': tooltipId, 'data-tip': true } : {};

  return (
    <ToggleContainer
      className={className}
      hasSecondaryLabel={!!secondaryLabel}
      labelPosition={labelPosition}
    >
      {tooltipId && tooltipText && <Tooltip id={tooltipId} text={tooltipText} />}
      <LabelContainer hasSecondaryLabel={!!secondaryLabel}>
        {primaryLabel && (
          <PrimaryLabel disabled={disabled} hasSecondaryLabel={!!secondaryLabel} htmlFor={id}>
            {primaryLabel}
          </PrimaryLabel>
        )}
        {secondaryLabel && (
          <SecondaryLabel disabled={disabled} htmlFor={id}>
            {secondaryLabel}
          </SecondaryLabel>
        )}
      </LabelContainer>
      <HiddenCheckbox
        aria-label={ariaLabel}
        checked={checked}
        data-testid={dataTestId}
        disabled={disabled}
        id={id}
        name={name}
        onChange={handleToggle}
        type='checkbox'
      />
      <StyledCheckbox
        checked={checked}
        disabled={disabled}
        htmlFor={id}
        id={`${id}-label`}
        {...tooltipProps}
      >
        <StyledSpan disabled={disabled} id={`${id}-custom-toggle`}>
          {checked ? (
            <StyledIcon
              checked={checked}
              className='icon-checkmark'
              disabled={disabled}
              name={checkedIcon}
              size='2xs'
              weight='solid'
            />
          ) : (
            <StyledIcon
              checked={checked}
              disabled={disabled}
              name={uncheckedIcon}
              size='2xs'
              weight='solid'
            />
          )}
        </StyledSpan>
      </StyledCheckbox>
      {isLoading && <Loader />}
    </ToggleContainer>
  );
};

export default Toggle;
