import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  InterceptorSceneKey,
  RenderingStrategy,
  StepKeys,
} from '../../../components/application/ai/AiBot/shared/types';

interface State {
  renderingStrategy: RenderingStrategy;
  shouldCongratsAnimationRun: boolean;
  shouldStepCompletionAnimationRun: boolean;
  activeStep: StepKeys;
  interceptorSceneKey?: InterceptorSceneKey;
}

const initialState: State = {
  renderingStrategy: RenderingStrategy.Closed,
  shouldCongratsAnimationRun: false,
  shouldStepCompletionAnimationRun: false,
  //TODO: Replace with real key
  activeStep: StepKeys.BuildAccount,
};

const aiBotSlice = createSlice({
  name: 'aiBotSlice',
  initialState,
  reducers: {
    setRenderingStrategy(state, action: PayloadAction<RenderingStrategy>) {
      state.renderingStrategy = action.payload;
    },
    runCongratsAnimation(state) {
      state.shouldCongratsAnimationRun = true;
    },
    resetCongratsAnimation(state) {
      state.shouldCongratsAnimationRun = false;
    },
    runStepCompletionAnimation(state) {
      state.shouldStepCompletionAnimationRun = true;
    },
    resetStepCompletionAnimation(state) {
      state.shouldStepCompletionAnimationRun = false;
    },
    setActiveStep(state, action: PayloadAction<StepKeys>) {
      state.activeStep = action.payload;
    },
    runInterceptorScene(state, action: PayloadAction<InterceptorSceneKey>) {
      state.interceptorSceneKey = action.payload;
    },
    resetInterceptorScene(state) {
      state.interceptorSceneKey = undefined;
    },
  },
});

export const {
  setRenderingStrategy,
  runCongratsAnimation,
  resetCongratsAnimation,
  runStepCompletionAnimation,
  resetStepCompletionAnimation,
  setActiveStep,
  // runInterceptorScene,
  resetInterceptorScene,
} = aiBotSlice.actions;
export default aiBotSlice.reducer;
