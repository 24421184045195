export type ImageType = 'upload' | 'giphy' | 'stock_image';

/**
 * This module defines actions related to image types, particularly for setting image URLs and handling image file uploads.
 */

/**
 * Possible actions for managing images.
 */
export type ImageTypeAction =
  /**
   * Action to set the image URL.
   * @property {string} type - The type of the action, set to 'setImageUrl'.
   * @property {string | null} selectedImageUrl - The selected image URL.
   * @property {ImageType} imageType - The type of the image (image input, giphy, stock_image).
   */
  | {
      type: 'setImageUrl';
      selectedImageUrl: string | null;
      imageType: ImageType;
    }
  /**
   * Action to set the image file for upload.
   * @property {string} type - The type of the action, set to 'setImageFile'.
   * @property {File | null} imageFile - The image file to be uploaded.
   */
  | {
      type: 'setImageFile';
      imageFile: File | null;
    }
  /**
   * Action to reset image data.
   * @property {string} type - The type of the action, set to 'resetImageData'.
   */
  | { type: 'resetImageData' };

type ImageState = {
  selectedImageUrl: string | null;
  imageFile: File | null;
  imageType: ImageType;
};

export const initialImageDataState: ImageState = {
  selectedImageUrl: null,
  imageFile: null,
  imageType: 'upload',
};

export const imageDataReducer = (_state: ImageState, action: ImageTypeAction): ImageState => {
  const { type } = action;

  switch (type) {
    case 'setImageUrl': {
      const { selectedImageUrl, imageType } = action;

      return { imageType, imageFile: null, selectedImageUrl };
    }
    case 'setImageFile': {
      const { imageFile } = action;

      return { imageType: 'upload', imageFile, selectedImageUrl: null };
    }
    case 'resetImageData': {
      return initialImageDataState;
    }
  }
};
