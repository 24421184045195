import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import ReactOnRails from 'react-on-rails';

import { RootState } from '../stores/store';
import { Data } from './types';

const axiosBaseQuery =
  ({
    baseUrl,
    requireAccountSlug,
  }: {
    baseUrl: string;
    requireAccountSlug: boolean;
  }): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    FetchBaseQueryError
  > =>
  async ({ url, method, body, params }, api) => {
    let accountSlug = '';

    if (requireAccountSlug) {
      const rootState = api.getState() as RootState;
      if (!rootState.auth.slug) throw new Error('Account Slug is not defined in state!');

      accountSlug = `/${rootState.auth.slug}`;
    }

    let data: string | FormData | undefined;
    if (body instanceof FormData) {
      data = body;
    } else if (typeof body === 'object') {
      data = JSON.stringify(body);
    }

    try {
      const response = await axios({
        method,
        data,
        params,
        url: `${baseUrl}${accountSlug}/${url}`,
        transformRequest: (data, headers) => {
          if (headers) {
            const authenticityHeaders = ReactOnRails.authenticityHeaders({});

            for (const authenticityHeadersKey in authenticityHeaders) {
              if (
                Object.prototype.hasOwnProperty.call(authenticityHeaders, authenticityHeadersKey)
              ) {
                headers[authenticityHeadersKey] = authenticityHeaders[authenticityHeadersKey];
              }
            }
            headers['Content-Type'] = 'application/json';
          }
          return data;
        },
      });
      let responseData = response.data as Data;
      if (responseData !== null && typeof responseData === 'object' && 'data' in responseData) {
        responseData = responseData.data;
      }

      return { data: responseData };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const error: FetchBaseQueryError = {
        status: err.response?.status ?? 'CUSTOM_ERROR',
        error: err.message,
        data: err.response?.data || err.message,
      };
      return { error };
    }
  };

export default axiosBaseQuery;
