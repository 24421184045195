import { GapCursor } from '@tiptap/pm/gapcursor';
import { Selection } from '@tiptap/pm/state';
import { Editor, FocusPosition, JSONContent, NodeViewProps } from '@tiptap/react';

type InsertEditorContent = {
  editor: Editor | NodeViewProps['editor'];
  content: JSONContent | string;
  initialFocus?: FocusPosition | null;
};

export const insertEditorContent = ({
  editor,
  content,
  initialFocus = null,
}: InsertEditorContent) => {
  const chain = editor.chain();

  if (initialFocus) {
    chain.focus(initialFocus);
  }

  return chain
    .insertContent(content)
    .command(({ tr }) => {
      const nodeAfter = tr.selection.$to.nodeAfter;
      if (nodeAfter?.isText) {
        tr.setSelection(Selection.near(tr.doc.resolve(tr.selection.$to.pos)));
      } else {
        tr.setSelection(new GapCursor(tr.selection.$to));
      }
      return true;
    })
    .focus()
    .run();
};
