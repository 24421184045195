import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useImageFlyoutContext } from '../../../../../contexts/ImageFlyoutContext';
import { readFile } from '../../../../../lib/readFile';
import FileInputDropzone from '../../components/FileInputDropzone/FileInputDropzone';

const ImageContainer = styled.div`
  overflow: hidden;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  text-align: center;
`;

const StyledImagePreview = styled.img`
  width: 95%;
  max-height: 16rem;
  object-fit: contain;
`;

interface Props {
  label: string;
  ariaLabel: string;
}

const ImagePreview = (props: Props) => {
  const { label, ariaLabel } = props;
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const { processFile, imageFile } = useImageFlyoutContext();

  useEffect(() => {
    const fetchImageDataUrl = async () => {
      if (imageFile) {
        const fileDataUrl = (await readFile(imageFile)) as string;

        setImagePreviewUrl(fileDataUrl);
      } else {
        setImagePreviewUrl(null);
      }
    };

    fetchImageDataUrl();
  }, [imageFile]);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      processFile(file);
    }
  };

  return imagePreviewUrl ? (
    <ImageContainer>
      <StyledImagePreview src={imagePreviewUrl} />
    </ImageContainer>
  ) : (
    <FileInputDropzone
      acceptedFiles='image/*'
      ariaLabel={ariaLabel}
      label={label}
      onFileChange={onFileChange}
    />
  );
};

export default ImagePreview;
