import React, { ReactNode, useCallback, useEffect, useRef } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
  onOutsideClick: (e: MouseEvent & { target: Element }) => void;
}

const OutsideClickHandler = ({ className, children, onOutsideClick }: Props) => {
  const setChildNodeRef = useRef<HTMLDivElement>(null);
  const onMouseUp = useCallback(
    (e: MouseEvent & { target: Element }) => {
      if (!(setChildNodeRef.current && setChildNodeRef.current.contains(e.target))) {
        onOutsideClick(e);
      }
    },
    [onOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp, true);
    return () => {
      document.removeEventListener('mouseup', onMouseUp, true);
    };
  }, [onMouseUp]);

  return (
    <div className={className} ref={setChildNodeRef}>
      {children}
    </div>
  );
};

export default OutsideClickHandler;
