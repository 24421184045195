import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { PixabayResponse } from '../../../../../../redux/services/pixabayApi/types';
import Icon from '../../../../../design_system/display/icons/Icon';
import Loader from '../../../../../design_system/Triage/Loader';
import ImageErrorState from '../../../shared/ImageErrorState';
import {
  AnimatedGradient,
  AnimatedIconContainer,
  ImageColumns,
  ImageContainer,
  LoaderWrapper,
} from '../../../shared/styles';
import EmptyPreview from '../preview_state/EmptyPreview';

type Props = {
  isLoading: boolean;
  isError: boolean;
  images: PixabayResponse | undefined;
  selectedImageUrl: string | null;
  handleImageClick: (url: string) => void;
};

const t = initTranslations('editor.image');

const StockImages = ({ isLoading, isError, images, selectedImageUrl, handleImageClick }: Props) => {
  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size='lg' />
      </LoaderWrapper>
    );
  }

  if (isError) {
    return (
      <ImageErrorState
        error={t('loading_error', { object: t('stock_images') })}
        imageType='stock_image'
      />
    );
  }

  if (!images?.hits?.length) {
    return <EmptyPreview />;
  }

  return (
    <ImageContainer>
      <ImageColumns>
        {images.hits.map((image) => (
          <AnimatedGradient
            className={selectedImageUrl === image.webformatURL ? 'selected' : ''}
            key={image.largeImageURL}
            onClick={() => handleImageClick(image.webformatURL)}
          >
            <img alt={image.tags} data-testid='stock-image' src={image.webformatURL} />
            <AnimatedIconContainer data-testid='selected-stock_image-icon' id='selected-image-icon'>
              <Icon name='check' size='2xs' />
            </AnimatedIconContainer>
          </AnimatedGradient>
        ))}
      </ImageColumns>
    </ImageContainer>
  );
};

export default StockImages;
