import React from 'react';
import styled from 'styled-components';

import DarkNoResultsImage from '../../../../../../images/searches_empty_state_dark.svg';
import LightNoResultsImage from '../../../../../../images/searches_empty_state.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import NoResults from '../../../../../design_system/Triage/NoResults';
import { fontSm4 } from '../../../../../styled/TypeSystem';

const t = initTranslations('editor.image');

const EmptyPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// DS Override: Reconfiguring styles to match designs in Figma
const StyledNoResults = styled(NoResults)`
  img {
    height: 7.4rem;
  }
  h4 {
    color: ${({ theme: { vars } }) => vars.textSubdued};
    font-weight: ${({ theme: { constants } }) => constants.fontRegular};
    ${fontSm4};
  }
`;

const EmptyPreview = () => {
  return (
    <EmptyPreviewContainer data-testid='image-empty-state'>
      <StyledNoResults
        darkImage={DarkNoResultsImage}
        heading={t('no_results_found')}
        lightImage={LightNoResultsImage}
        minHeight='10.1rem'
        showBorder={false}
        showSubheader={false}
      />
    </EmptyPreviewContainer>
  );
};

export default EmptyPreview;
