import { createSlice } from '@reduxjs/toolkit';

import { SaveState } from '../../../contexts/EditorContext';
import { useAppDispatch, useAppSelector } from '../../hooks';

type AutosaveState = {
  status: SaveState;
};

const initialState: AutosaveState = {
  status: 'no-changes',
};

const autosaveSlice = createSlice({
  name: 'autosave',
  initialState,
  reducers: {
    setAutosaveStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

const { setAutosaveStatus } = autosaveSlice.actions;

export const useSetAutosaveStatus = () => {
  const dispatch = useAppDispatch();
  return (status: string) => dispatch(setAutosaveStatus(status));
};

export const useAutosaveStatus = () => useAppSelector((state) => state.autosave.status);

export default autosaveSlice.reducer;
