import { NodeViewProps } from '@tiptap/react';
import React, { ReactElement, ReactNode, useContext, useMemo } from 'react';

import {
  EmbedCardAttributes,
  EmbedExtensionName,
  IframeAttributes,
  ImageAttributes,
} from '../components/application/editor/tiptap_extensions/types';

export type NodeAttributes = EmbedCardAttributes | IframeAttributes | ImageAttributes;

type EditorEmbedOptionsContext = {
  currentAttributes: NodeAttributes;
  deleteNode: NodeViewProps['deleteNode'];
  editor: NodeViewProps['editor'];
  updateAttributes: NodeViewProps['updateAttributes'];
};

type EditorEmbedOptionsProps = {
  children: ReactNode;
} & EditorEmbedOptionsContext;

const EditorEmbedOptionsContext = React.createContext({} as EditorEmbedOptionsContext);
export const useEditorEmbedOptionsContext = () => useContext(EditorEmbedOptionsContext);

const EditorEmbedOptionsProvider = ({
  children,
  currentAttributes,
  deleteNode,
  editor,
  updateAttributes,
}: EditorEmbedOptionsProps): ReactElement => {
  const embedOptions = useMemo(() => {
    let options = {
      currentAttributes,
      deleteNode,
      editor,
      updateAttributes,
    };

    if (currentAttributes.extensionName === EmbedExtensionName.IMAGE) {
      const { isShadow, isRoundCorners, isBorder } = currentAttributes;
      options = {
        ...options,
        currentAttributes: {
          ...currentAttributes,
          isBorder,
          isShadow,
          isRoundCorners,
        },
      };
    }

    return options;
  }, [currentAttributes, deleteNode, editor, updateAttributes]);

  return (
    <EditorEmbedOptionsContext.Provider value={{ ...embedOptions }}>
      {children}
    </EditorEmbedOptionsContext.Provider>
  );
};
export { EditorEmbedOptionsProvider };
