import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '../../hooks';

interface State {
  isExpanded: boolean;
  isExpandedByCollapseButton: boolean | null;
  isExpandedBySidebarHover: boolean;
}

type StateName = 'isExpanded' | 'isExpandedByCollapseButton' | 'isExpandedBySidebarHover';

const initialState: State = {
  isExpanded: true,
  isExpandedByCollapseButton: null,
  isExpandedBySidebarHover: false,
};

const collapsibleSidebarSlice = createSlice({
  name: 'collapsibleSidebarSlice',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isExpanded = !state.isExpanded;
      state.isExpandedByCollapseButton = !state.isExpanded;
    },
    collapseSidebar(state) {
      state.isExpanded = false;
    },
    expandSidebar(state) {
      state.isExpanded = true;
    },
    collapseSidebarByHover(state) {
      state.isExpandedBySidebarHover = false;
    },
    expandSidebarByHover(state) {
      state.isExpandedBySidebarHover = true;
    },
  },
});

export const {
  collapseSidebar,
  collapseSidebarByHover,
  expandSidebar,
  expandSidebarByHover,
  toggleSidebar,
} = collapsibleSidebarSlice.actions;

export default collapsibleSidebarSlice.reducer;

export const useCheckSidebarState = (stateName: StateName) =>
  useAppSelector(
    ({ collapsibleSidebar }: { collapsibleSidebar: State }) => collapsibleSidebar[stateName]
  );
