import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ActivityLogFilters } from '../../../components/application/reports/ActivityLogPage/ActivityLogTab/ActivityLogTabTypes';
import { PublishStatuses, VerifyContentStatuses } from '../../services/resourceApis/reports/types';

interface ActivityLogPageState {
  activityLog: {
    page: number;
    filters: ActivityLogFilters;
  };
}

interface ReportPageFilters {
  groupIds: string[];
  roleIds: string[];
  teamIds: string[];
}

interface ReportPageState {
  page: number;
  filters: ReportPageFilters;
}

export interface AllCurriculumsFilters extends ReportPageFilters {
  ownerIds: string[];
  completionScoreMin?: number;
  completionScoreMax?: number;
  verifyContentStatuses: VerifyContentStatuses;
  publishStatuses: PublishStatuses;
}

interface AllCurriculumsReportState extends ReportPageState {
  page: number;
  searchTerm: string;
  filters: AllCurriculumsFilters;
  appliedFiltersCount: number;
}

export type LastActiveAt = 'yesterday' | 'today' | 'week' | 'month' | 'year' | null;

export interface AllUsersReportPageFilterState extends ReportPageFilters {
  completionScoreMin: number;
  completionScoreMax: number;
  lastActiveAt: LastActiveAt;
}

interface AllUsersReportPageState {
  page: number;
  searchTerm: string;
  filters: AllUsersReportPageFilterState;
  appliedFiltersCount: number;
}

export interface IndividualUserReportPageFilterState extends ReportPageFilters {
  completionScoreMin: number;
  completionScoreMax: number;
  eSignature: string[];
  tests: string[];
  statuses: string[];
}

export interface ElementReportPageFilterState {
  completionScoreMin: number;
  completionScoreMax: number;
  contentTypeFilters: string[];
}

interface IndividualUserReportPageState {
  page: number;
  searchTerm: string;
  filters: IndividualUserReportPageFilterState;
}

interface ElementReportPageState {
  page: number;
  searchTerm: string;
  filters: ElementReportPageFilterState;
}

export interface UserAssignmentReportPageFilterState extends ReportPageFilters {
  completionScoreMin?: number;
  completionScoreMax?: number;
}

interface UserAssignmentReportPageState {
  page: number;
  searchTerm: string;
  filters: UserAssignmentReportPageFilterState;
}

interface PageState extends ActivityLogPageState {
  allCurriculumsReport: AllCurriculumsReportState;
  userAssignmentReport: UserAssignmentReportPageState;
  allUsersReport: AllUsersReportPageState;
  individualUserReport: IndividualUserReportPageState;
  elementReport: ElementReportPageState;
}

const initialState: PageState = {
  activityLog: {
    page: 1,
    filters: {
      activityType: '',
      userIds: [],
      curriculumIds: [],
      timeRange: 'month',
    },
  },
  allCurriculumsReport: {
    page: 1,
    searchTerm: '',
    filters: {
      groupIds: [],
      roleIds: [],
      teamIds: [],
      ownerIds: [],
      verifyContentStatuses: [],
      completionScoreMin: 0,
      completionScoreMax: 100,
      publishStatuses: [],
    },
    appliedFiltersCount: 0,
  },
  userAssignmentReport: {
    page: 1,
    searchTerm: '',
    filters: {
      completionScoreMin: 0,
      completionScoreMax: 100,
      groupIds: [],
      roleIds: [],
      teamIds: [],
    },
  },
  allUsersReport: {
    page: 1,
    searchTerm: '',
    filters: {
      completionScoreMin: 0,
      completionScoreMax: 100,
      lastActiveAt: null,
      groupIds: [],
      roleIds: [],
      teamIds: [],
    },
    appliedFiltersCount: 0,
  },
  individualUserReport: {
    page: 1,
    searchTerm: '',
    filters: {
      completionScoreMin: 0,
      completionScoreMax: 100,
      groupIds: [],
      roleIds: [],
      teamIds: [],
      eSignature: [],
      tests: [],
      statuses: [],
    },
  },
  elementReport: {
    page: 1,
    searchTerm: '',
    filters: {
      completionScoreMin: 0,
      completionScoreMax: 100,
      contentTypeFilters: [],
    },
  },
};

const appliedCurriculumsFiltersCount = (payload: AllCurriculumsFilters) => {
  return (
    payload.groupIds.length +
    ((payload.completionScoreMin && payload.completionScoreMin > 0) ||
    (payload.completionScoreMax && payload.completionScoreMax < 100)
      ? 1
      : 0) +
    payload.verifyContentStatuses.length +
    (payload.publishStatuses.length === 1 && payload.publishStatuses[0] === 'published' ? 0 : 1)
  );
};

const appliedUsersFiltersCount = (payload: AllUsersReportPageFilterState) => {
  return (
    payload.groupIds.length +
    (payload.lastActiveAt === null ? 0 : 1) +
    (payload.completionScoreMin > 0 || payload.completionScoreMax < 100 ? 1 : 0)
  );
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setActivityLogPage(state, action: PayloadAction<number>) {
      state.activityLog.page = action.payload;
    },
    setActivityLogFilters(state, action: PayloadAction<Omit<ActivityLogFilters, 'timeRange'>>) {
      state.activityLog.filters = { ...state.activityLog.filters, ...action.payload };
      state.activityLog.page = 1;
    },
    setActivityLogTimeRangeFilter(
      state,
      action: PayloadAction<Pick<ActivityLogFilters, 'timeRange'>>
    ) {
      state.activityLog.filters.timeRange = action.payload.timeRange;
      state.activityLog.page = 1;
    },
    setAllCurriculumsReportPage(state, action: PayloadAction<number>) {
      state.allCurriculumsReport.page = action.payload;
    },
    setAllCurriculumsReportFilters(state, action: PayloadAction<AllCurriculumsFilters>) {
      state.allCurriculumsReport.page = 1;
      state.allCurriculumsReport.filters = action.payload;
      state.allCurriculumsReport.appliedFiltersCount = appliedCurriculumsFiltersCount(
        action.payload
      );
    },
    setAllCurriculumsReportSearchTerm(state, action: PayloadAction<string>) {
      state.allCurriculumsReport.page = 1;
      state.allCurriculumsReport.searchTerm = action.payload;
    },
    setUserAssignmentReportPage(state, action: PayloadAction<number>) {
      state.userAssignmentReport.page = action.payload;
    },
    setUserAssignmentReportFilters(
      state,
      action: PayloadAction<UserAssignmentReportPageFilterState>
    ) {
      state.userAssignmentReport.page = 1;
      state.userAssignmentReport.filters = action.payload;
    },
    setUserAssignmentReportSearchTerm(state, action: PayloadAction<string>) {
      state.userAssignmentReport.page = 1;
      state.userAssignmentReport.searchTerm = action.payload;
    },
    setAllUsersReportPage(state, action: PayloadAction<number>) {
      state.allUsersReport.page = action.payload;
    },
    setAllUsersReportSearchTerm(state, action: PayloadAction<string>) {
      state.allUsersReport.page = 1;
      state.allUsersReport.searchTerm = action.payload;
    },
    setAllUsersReportFilters(state, action: PayloadAction<AllUsersReportPageFilterState>) {
      state.allUsersReport.page = 1;
      state.allUsersReport.filters = action.payload;
      state.allUsersReport.appliedFiltersCount = appliedUsersFiltersCount(action.payload);
    },
    setIndividualUserReportPage(state, action: PayloadAction<number>) {
      state.individualUserReport.page = action.payload;
    },
    setIndividualUserReportSearchTerm(state, action: PayloadAction<string>) {
      state.individualUserReport.page = 1;
      state.individualUserReport.searchTerm = action.payload;
    },
    setIndividualUserReportFilters(
      state,
      action: PayloadAction<IndividualUserReportPageFilterState>
    ) {
      state.individualUserReport.page = 1;
      state.individualUserReport.filters = action.payload;
    },
    setElementReportPage(state, action: PayloadAction<number>) {
      state.elementReport.page = action.payload;
    },
    setElementReportSearchTerm(state, action: PayloadAction<string>) {
      state.elementReport.page = 1;
      state.elementReport.searchTerm = action.payload;
    },
    setElementReportFilters(state, action: PayloadAction<ElementReportPageFilterState>) {
      state.elementReport.page = 1;
      state.elementReport.filters = action.payload;
    },
  },
});

export const {
  setActivityLogPage,
  setActivityLogFilters,
  setAllCurriculumsReportPage,
  setAllCurriculumsReportFilters,
  setAllCurriculumsReportSearchTerm,
  setUserAssignmentReportPage,
  setUserAssignmentReportFilters,
  setUserAssignmentReportSearchTerm,
  setAllUsersReportPage,
  setAllUsersReportSearchTerm,
  setAllUsersReportFilters,
  setIndividualUserReportPage,
  setIndividualUserReportSearchTerm,
  setIndividualUserReportFilters,
  setElementReportSearchTerm,
  setElementReportPage,
  setElementReportFilters,
  setActivityLogTimeRangeFilter,
} = reportsSlice.actions;
export default reportsSlice.reducer;
