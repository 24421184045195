import React from 'react';
import { preventDefaultLinkClickBehavior } from 'type-route';

import initTranslations from '../../../lib/initTranslations';
import { session, useRouterHelper } from '../../application/publicApplication/applicationRouter';
import { LinkAsButtonProps, LinkAsLinkProps } from './LinkTypes';
import { PrefixIcon, StyledLink, SuffixIcon, getIconSize } from './styles';

const t = initTranslations('design_system');

export type Props = LinkAsButtonProps | LinkAsLinkProps;

const Link = (props: Props) => {
  const routeState = useRouterHelper();
  const {
    id,
    dataFor,
    className,
    behavesAs = 'a',
    color = 'accent',
    disabled = false,
    underlineBehavior = 'static',
    prefixIconName,
    size = 'md',
    text,
    draggable = false,
  } = props;

  const { onClick, buttonType = 'button' } = props as LinkAsButtonProps;
  const { target, href, external = false } = props as LinkAsLinkProps;

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    /*
      type-route: Temporarily needed while app is being migrated to fully use type-route library.
      When we are fully migrated we will require a route to be passed from the router instead of href
    */

    if (routeState && preventDefaultLinkClickBehavior(event)) {
      const button = event.target as HTMLAnchorElement;
      button.blur();
      session.push(href);
    }
  };

  const commonProps = {
    id,
    size,
    color,
    disabled,
    className,
    underlineBehavior,
    'data-for': dataFor,
    'data-tip': !!dataFor,
    draggable,
  };

  const buttonProps = {
    ...commonProps,
    onClick,
    type: buttonType,
  };

  const linkProps = {
    ...commonProps,
    onClick: handleLinkClick,
    target: external ? '_blank' : target,
    'aria-label': external ? t('aria_label_external_link', { text }) : undefined,
    href,
  };

  return (
    <>
      {behavesAs === 'button' ? (
        <StyledLink {...buttonProps} as='button'>
          {prefixIconName && <PrefixIcon name={prefixIconName} size={getIconSize(size)} />}
          {text}
        </StyledLink>
      ) : (
        <StyledLink {...linkProps} as='a'>
          {prefixIconName && <PrefixIcon name={prefixIconName} size={getIconSize(size)} />}
          {text}
          {external && <SuffixIcon name='arrow-up-right-from-square' size={getIconSize(size)} />}
        </StyledLink>
      )}
    </>
  );
};

export default Link;
