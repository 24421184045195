import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  isResponsibilityDetailsModalOpen: boolean;
  selectedResponsibilityId: string | null;
  mode?: 'edit' | 'view';
}

const initialState: State = {
  isResponsibilityDetailsModalOpen: false,
  selectedResponsibilityId: null,
  mode: 'view',
};

const responsibilityDetailsModalSlice = createSlice({
  name: 'responsibilityModal',
  initialState,
  reducers: {
    openResponsibilityDetailsModal: (
      state,
      action: PayloadAction<{ id: string; mode?: 'edit' | 'view' }>
    ) => {
      state.isResponsibilityDetailsModalOpen = true;
      state.selectedResponsibilityId = action.payload.id;
      state.mode = action.payload.mode || 'view';
    },
    closeResponsibilityDetailsModal: (state) => {
      state.isResponsibilityDetailsModalOpen = false;
      state.selectedResponsibilityId = null;
      state.mode = 'view';
    },
  },
});

export const { openResponsibilityDetailsModal, closeResponsibilityDetailsModal } =
  responsibilityDetailsModalSlice.actions;
export default responsibilityDetailsModalSlice.reducer;
