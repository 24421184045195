import { EmbedOptionBarItem } from '../components/EmbedOptionsBar/EmbedOptionsBar';

export const embedCardOptions = ({
  includeViewOption,
  simpleOptions,
}: {
  includeViewOption: boolean;
  simpleOptions?: boolean;
}) => {
  let optionBarItems: EmbedOptionBarItem[] = ['size', 'open', 'copy', 'delete'];

  if (includeViewOption) {
    optionBarItems = ['size', 'view', 'open', 'copy', 'delete'];
  }

  if (simpleOptions) {
    optionBarItems = ['delete'];
  }

  return optionBarItems;
};
