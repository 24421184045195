import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';

import { EmbedlyParams, EmbedlyResponse } from '../../types/EmbedlyResponse';

const decodeHtmlEntities = (str: string | undefined) => {
  return str
    ?.replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&nbsp;/g, ' ')
    .replace(/&copy;/g, '©')
    .replace(/&reg;/g, '®')
    .replace(/&trade;/g, '™')
    .replace(/&euro;/g, '€');
};

export const embedlyApi = createApi({
  reducerPath: 'embedlyApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.embedly.com/' }),
  endpoints: (builder) => ({
    getEmbed: builder.query<EmbedlyResponse, EmbedlyParams>({
      query: ({ apiKey, url }: EmbedlyParams) => ({
        url: '1/oembed',
        params: { url, key: apiKey },
      }),
      transformResponse: (response: EmbedlyResponse) => {
        return {
          ...response,
          title: decodeHtmlEntities(response.title) || '',
          description: decodeHtmlEntities(response.description) || '',
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const getEmbedlyDisplay = ({ url, apiKey }: EmbedlyParams) => {
  const TIMEOUT = 30000; // 30 seconds

  const timeoutPromise = new Promise<never>((_, reject) => {
    setTimeout(() => reject(new Error('Request timed out')), TIMEOUT);
  });

  const fetchPromise = fetch(
    `https://i.embed.ly/1/display?animate=true&compresspng=false&url=${url}&key=${apiKey}`,
    {
      headers: { Accept: 'image/*' },
    }
  );

  return Promise.race([fetchPromise, timeoutPromise]);
};
