import { Modifier, ModifierPhases, State } from '@popperjs/core';
import React, { useMemo } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import type { StrictModifier } from 'react-popper';
import styled from 'styled-components';

import Portal from './application/Portal';

export const POPPABLE_Z_INDEX = 20;

const Wrapper = styled.div<{ usePortal: boolean }>`
  z-index: ${({ usePortal }) => (usePortal ? 1000 : POPPABLE_Z_INDEX)};
`;

export type Placement =
  | 'auto-end'
  | 'auto-start'
  | 'auto'
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export type Strategy = 'absolute' | 'fixed';

export type Modifiers = StrictModifier[];

interface Props {
  id?: string;
  placement?: Placement;
  strategy?: Strategy;
  isOpen: boolean;
  onClick: () => void;
  menuStyling?: React.CSSProperties;
  modifiers?: Modifiers;
  trigger: JSX.Element;
  item: JSX.Element;
  useMenuWidth?: boolean;
  usePortal?: boolean;
}

const Poppable = ({
  id,
  placement = 'auto',
  onClick,
  strategy = 'fixed',
  isOpen,
  menuStyling = {},
  modifiers = [],
  trigger,
  item,
  useMenuWidth = false,
  usePortal = false,
}: Props) => {
  const fullWidthModifiers = useMemo<Modifier<'sameWidth', never>[]>(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite' as ModifierPhases,
        requires: ['computeStyles'],
        fn: ({ state }: { state: State }) => {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
        effect: ({ state }: { state: State }) => {
          if (state.elements.reference instanceof HTMLElement) {
            state.elements.popper.style.maxWidth = `${state.elements.reference.offsetWidth}px`;
          }
          return () => {
            state.elements.popper.style.maxWidth = '';
          };
        },
      },
    ],
    []
  );

  const combinedModifiers = useMemo(
    () => [...fullWidthModifiers, ...modifiers],
    [fullWidthModifiers, modifiers]
  );

  function onTrigger(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  }

  const PopperContainerComponent = usePortal ? Portal : React.Fragment;

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div onClick={onTrigger} ref={ref}>
            {trigger}
          </div>
        )}
      </Reference>
      {isOpen && (
        <PopperContainerComponent>
          <Popper
            modifiers={useMenuWidth ? combinedModifiers : modifiers}
            placement={placement}
            strategy={strategy}
          >
            {({ ref, style, placement }) => (
              <Wrapper
                data-placement={placement}
                id={id}
                ref={ref}
                style={{ ...style, ...menuStyling }}
                usePortal={usePortal}
              >
                {item}
              </Wrapper>
            )}
          </Popper>
        </PopperContainerComponent>
      )}
    </Manager>
  );
};

export default Poppable;
