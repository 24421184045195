import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { useImageFlyoutContext } from '../../../../../../contexts/ImageFlyoutContext';
import usePrivateConfigs from '../../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../../lib/initTranslations';
import {
  giphyApi,
  useGetTrendingQuery,
} from '../../../../../../redux/services/gifApis/giphyService';
import InputField from '../../../../../design_system/Triage/InputField';
import { EditorInputContainer } from '../../../shared/styles';
import Gifs from './Gifs';

const t = initTranslations('editor.image');

const GifInput = () => {
  const { configs } = usePrivateConfigs();
  const { imageDataDispatch, selectedImageUrl, imageType, imageError } = useImageFlyoutContext();
  const giphyApiKey = configs['GIPHY_KEY'];
  const {
    data: TrendingData,
    isLoading: trendingLoading,
    error: trendingError,
  } = useGetTrendingQuery({ api_key: giphyApiKey }, { refetchOnMountOrArgChange: true });
  const [giphyRequest, giphyResponse] = giphyApi.useLazyGetSearchQuery();
  const { data: SearchData, isLoading: searchLoading, error: searchError } = giphyResponse;
  const [searchTerm, setSearchTerm] = useState('');

  const handleImageClick = useCallback(
    (url: string) => {
      if (selectedImageUrl === url) {
        imageDataDispatch({ type: 'resetImageData' });
      } else {
        imageDataDispatch({ type: 'setImageUrl', selectedImageUrl: url, imageType: 'giphy' });
      }
    },
    [imageDataDispatch, selectedImageUrl]
  );

  const handleSearchSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value) {
      debounce((q: string) => giphyRequest({ q, api_key: giphyApiKey }), 500)(value);
    }
  };

  const giphyData = useMemo(() => {
    if (searchTerm && SearchData) {
      imageDataDispatch({ type: 'resetImageData' });

      return SearchData.data;
    } else if (TrendingData) {
      return TrendingData.data;
    } else return [];
  }, [searchTerm, SearchData, TrendingData, imageDataDispatch]);

  return (
    <EditorInputContainer>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputField
          data-testid='gif-input-field'
          id='gif-input-field'
          label={t('gif_label')}
          name='gif-input-field'
          onChange={handleSearchSubmit}
          placeholder={t('gif_placeholder')}
        />
      </form>
      <Gifs
        giphyData={giphyData}
        handleImageClick={handleImageClick}
        isError={searchError || trendingError || (imageType === 'giphy' && imageError)}
        isLoading={searchLoading || trendingLoading}
        selectedImageUrl={selectedImageUrl}
      />
    </EditorInputContainer>
  );
};

export default GifInput;
