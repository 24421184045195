import { createSlice } from '@reduxjs/toolkit';

interface State {
  slug?: string;
  forbiddenRedirectInProgress: boolean;
  unauthorizedRedirectInProgress: boolean;
  noResourceRedirectInProgress: boolean;
}

const initialState: State = {
  forbiddenRedirectInProgress: false,
  unauthorizedRedirectInProgress: false,
  noResourceRedirectInProgress: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    beginForbiddenRedirect: (state) => {
      state.forbiddenRedirectInProgress = true;
    },
    endForbiddenRedirect: (state) => {
      state.forbiddenRedirectInProgress = false;
    },
    beginUnauthorizedRedirect: (state) => {
      state.unauthorizedRedirectInProgress = true;
    },
    endUnauthorizedRedirect: (state) => {
      state.unauthorizedRedirectInProgress = false;
    },
    beginNoResourceRedirect: (state) => {
      state.noResourceRedirectInProgress = true;
    },
    endNoResourceRedirect: (state) => {
      state.noResourceRedirectInProgress = false;
    },
  },
});

export const {
  beginForbiddenRedirect,
  endForbiddenRedirect,
  beginUnauthorizedRedirect,
  endUnauthorizedRedirect,
  beginNoResourceRedirect,
  endNoResourceRedirect,
} = authSlice.actions;
export default authSlice.reducer;
