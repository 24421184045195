import { useContext } from 'react';

import { PrivateConfigsContext } from '../contexts/PrivateConfigsContext';

const usePrivateConfigs = () => {
  const { configs } = useContext(PrivateConfigsContext);

  if (!configs) {
    throw new Error('Private configs not initialized');
  }

  return { configs };
};

export default usePrivateConfigs;
