import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import IconButton from '../../../../design_system/buttons/IconButton/IconButton';

const t = initTranslations('editor.embed_options_bar');

// DS Override: Adjust size and background-color per designs
const StyledIconButton = styled(IconButton)`
  width: 2.25rem;
  height: 2.25rem;
  justify-content: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const BaseThreeDotButtonWrapper = styled.div<{ optionsBarVisible: boolean }>`
  display: ${({ optionsBarVisible }) => (optionsBarVisible ? 'initial' : 'none')};
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerMd2};
  right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type Props = {
  id: string;
};

const ThreeDotButton = ({ id }: Props) => {
  return (
    <StyledIconButton
      ariaLabel={t('aria_label_three_dot')}
      className='embed-option-three-dot'
      dataTestId='embed-option-three-dot'
      id={id}
      name='ellipsis-v'
    />
  );
};

export default ThreeDotButton;
