import { createSlice } from '@reduxjs/toolkit';

type OverlayState = {
  [key: string]: boolean;
};

const initialState: OverlayState = {};

const overlaysSlice = createSlice({
  name: 'overlays',
  initialState,
  reducers: {
    setShowOverlay: (state, action) => {
      state[action.payload.overlayId] = action.payload.showOverlay;
    },
  },
});

export const { setShowOverlay } = overlaysSlice.actions;
export default overlaysSlice.reducer;
