import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { useTheme } from 'styled-components';

import TooltipContent from './TooltipContent/TooltipContent';
import { TooltipProps } from './types';

const StyledTooltip = styled(ReactTooltip)`
  /* using !important to override styles from the library */
  padding: ${({ theme: { constants } }) => constants.spacerSm3} !important;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd} !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  max-width: 22rem !important;
  white-space: normal;
`;

const Tooltip = ({
  place = 'top',
  id,
  className,
  effect = 'solid',
  text,
  content,
  globalEventOff,
  isCapture = true,
  delayShow = 0,
}: TooltipProps) => {
  const theme = useTheme();

  return (
    <StyledTooltip
      backgroundColor={theme.vars.foundationSurface2}
      className={className}
      delayShow={delayShow}
      effect={effect}
      globalEventOff={globalEventOff}
      id={id}
      isCapture={isCapture}
      multiline
      place={place}
      role='status'
    >
      <TooltipContent {...(text ? { text } : { content })} />
    </StyledTooltip>
  );
};

export default Tooltip;
