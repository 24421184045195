import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type QuestionAnswer = {
  questionId: number;
  isAnswered: boolean;
};

interface State {
  currentQuestionIndex: number;
  questionAnswersData: QuestionAnswer[];
}

const initialState: State = {
  currentQuestionIndex: 0,
  questionAnswersData: [],
};

const surveyConsumeSlice = createSlice({
  name: 'surveyConsumeSlice',
  initialState,
  reducers: {
    setQuestionAnswersData(state, action: PayloadAction<QuestionAnswer[]>) {
      state.questionAnswersData = action.payload;
    },
    setCurrentQuestionIndex(state, action: PayloadAction<number>) {
      state.currentQuestionIndex = action.payload;
    },
    setQuestionAnswered(state, action: PayloadAction<{ questionId: number; isAnswered: boolean }>) {
      const question = state.questionAnswersData.find(
        (answer) => answer.questionId === action.payload.questionId
      );
      if (question) {
        question.isAnswered = action.payload.isAnswered;
      }
    },
  },
});

export const { setCurrentQuestionIndex, setQuestionAnswersData, setQuestionAnswered } =
  surveyConsumeSlice.actions;
export default surveyConsumeSlice.reducer;
