import { createSlice } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from '../../hooks';

type ModalState = {
  [key: string]: boolean;
};

const initialState: ModalState = {};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setShowModal: (state, action) => {
      state[action.payload.modalName] = action.payload.showModal;
    },
  },
});

const { setShowModal } = modalsSlice.actions;
export const useDispatchSetShowModal = () => {
  const dispatch = useAppDispatch();

  return (modalName: string, showModal: boolean) =>
    dispatch(setShowModal({ modalName, showModal }));
};
export const useCheckModalState = (modalName: string) =>
  useAppSelector((state) => state.modals[modalName]);
export default modalsSlice.reducer;
