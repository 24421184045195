import { v4 as uuidv4 } from 'uuid';

export const generateRandomFileName = (): string => {
  const date = new Date();
  const dateString = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(
    date.getDate()
  ).padStart(2, '0')}`;
  const randomUUID = uuidv4();

  return `${dateString}-${randomUUID}`;
};
