import React from 'react';
import styled from 'styled-components';

import Icon from '../../display/icons/Icon';
import { IconSize } from '../../display/icons/Icon/IconTypes';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export interface Props {
  className?: string;
  size?: IconSize;
}

const Loader = ({ className, size = 'xs' }: Props) => {
  return (
    <LoaderWrapper className={className}>
      <Icon fixedWidth name='spinner-third' size={size} spinSpeed='fast' weight='solid' />
    </LoaderWrapper>
  );
};

export default Loader;
