import styled from 'styled-components';

import { fontSm4 } from '../../../styled/TypeSystem';
import { getTextType } from './AssistiveText';
import { Type } from './AssistiveTextTypes';

export const Span = styled.span<{ type: Type }>`
  cursor: default;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ type, theme: { vars } }) => getTextType(type, vars)};
  display: flex;
`;

export const Text = styled.p<{ id: string; addMarginForIcon?: boolean; wrapErrors?: boolean }>`
  ${(props) => (props.wrapErrors ? `white-space: pre-line` : '')};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: inherit;
  margin: 0;
  ${fontSm4};

  ${({ addMarginForIcon, theme: { constants } }) =>
    addMarginForIcon && `margin-left: ${constants.spacerSm2};`}

  & * {
    font-size: inherit;
    line-height: inherit;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  padding: ${({ theme: { constants } }) => constants.spacerSm1};
`;

export const AssistiveTextWrapper = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm1};
`;
