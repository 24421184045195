import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import { insertEditorContent } from '../../../lib/insertEditorContent';
import { EmbedExtensionName } from '../../../tiptap_extensions/types';
import { EmbedOptionBarItemProps } from '../EmbedOptionsBar';
import Dropdown, { MenuOption } from './shared/Dropdown';

const t = initTranslations('editor.embed_options_bar');

const MenuOptionTextWrapper = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const EMBED_VIEWS: {
  extension: EmbedExtensionName;
  text: string;
  icon: IconName;
}[] = [
  { extension: EmbedExtensionName.IFRAME, text: t('view.frame'), icon: 'film' },
  { extension: EmbedExtensionName.EMBED_CARD, text: t('view.card'), icon: 'cards-blank' },
];

const ViewDropdown = ({ hideOptionsBar, openMenuId, setOpenMenuId }: EmbedOptionBarItemProps) => {
  const { currentAttributes, editor } = useEditorEmbedOptionsContext();

  const { extensionName: activeExtension } = currentAttributes;

  const activeIndex = Math.max(
    EMBED_VIEWS.findIndex(({ extension }) => extension === activeExtension),
    0
  );

  const updateView = useCallback(
    (event: React.MouseEvent, extensionName: EmbedExtensionName) => {
      if (extensionName === activeExtension) {
        hideOptionsBar();
        return;
      }

      // Avoid triggering onClick handler for dropdown because because component
      // (with dropdown) is removed below.
      event.stopPropagation();

      const { altViewAttrs, ...currentViewAttrs } = currentAttributes;
      const newAttributes = {
        ...altViewAttrs,
        size: currentViewAttrs.size,
        altViewAttrs: currentViewAttrs,
      };

      editor.chain().focus().deleteCurrentNode().run();
      insertEditorContent({
        editor,
        content: {
          type: extensionName,
          attrs: newAttributes,
        },
      });
    },
    [activeExtension, currentAttributes, editor, hideOptionsBar]
  );

  const options = EMBED_VIEWS.map(({ extension, text, icon }) => {
    const isActive = extension === activeExtension;
    return (
      <MenuOption
        className='view-menu-option'
        isActive={isActive}
        key={extension}
        onClick={(event) => updateView(event, extension)}
      >
        <Icon fixedWidth name={icon} size='2xs' />
        <MenuOptionTextWrapper>{text}</MenuOptionTextWrapper>
      </MenuOption>
    );
  });

  return (
    <Dropdown
      buttonText={EMBED_VIEWS[activeIndex].text}
      id='view-dropdown'
      openMenuId={openMenuId}
      options={options}
      setOpenMenuId={setOpenMenuId}
    />
  );
};

export default ViewDropdown;
