import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ExpandedPalette, LimitedPalette, PaletteBoard } from 'saguaro';

import initTranslations from '../../../../../lib/initTranslations';

const t = initTranslations('editor');

export const SAVE_TIMEOUT = 2000;

export type embedMediaSize = 'small' | 'medium' | 'large';
type embedMediaWidths = {
  [size in embedMediaSize]: number;
};

export const MEDIA_SIZE_TO_WIDTH: embedMediaWidths = {
  small: 320,
  medium: 540,
  large: 758,
};

type TextAlignOptions =
  | { align: 'left'; icon: Extract<IconName, 'align-left'> }
  | { align: 'center'; icon: Extract<IconName, 'align-center'> }
  | { align: 'right'; icon: Extract<IconName, 'align-right'> };

export const FONT_SIZES = [
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '18',
  '24',
  '30',
  '32',
  '36',
  '48',
  '60',
  '72',
  '96',
];

export const FONT_FAMILIES = window.EDITOR_FONTS;

export const TEXT_ALIGN: TextAlignOptions[] = [
  { align: 'left', icon: 'align-left' },
  { align: 'center', icon: 'align-center' },
  { align: 'right', icon: 'align-right' },
];

export const LINE_HEIGHTS = [
  { lineHeight: '1', label: t('line_height.single') },
  { lineHeight: '1.15', label: '1.15' },
  { lineHeight: '1.5', label: '1.5' },
  { lineHeight: '2', label: t('line_height.double') },
  { lineHeight: '2.5', label: '2.5' },
  { lineHeight: '3', label: t('line_height.triple') },
];

export const MAX_FILE_SIZE_MB = 50;

// Maximum number of files a user can drag/drop or paste into the editor at once
export const MAX_FILE_UPLOAD_BATCH_SIZE = 10;

export const GOOGLE_DOCS_BASE_URL = 'docs.google.com/document';

// See Mozilla documentation for additional MIME types: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const SUPPORTED_FILE_TYPES = [
  '.pdf',
  'application/pdf',
  '.csv',
  'text/csv',
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xls',
  'application/vnd.ms-excel',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.doc',
  'application/msword',
  '.mp3',
  '.ppt',
  'application/vnd.ms-powerpoint',
  '.pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'audio/mpeg',
  '.yaml',
  'text/yaml',
  'application/x-yaml',
];

const SUPPORTED_IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/tiff',
  'image/bmp',
];

export const SUPPORTED_UPLOAD_TYPES_BY_CONTENT_TYPE = {
  file: SUPPORTED_FILE_TYPES,
  image: SUPPORTED_IMAGE_TYPES,
};

export const SUPPORTED_PDF_FILE_TYPES = ['.pdf', 'application/pdf'];

export const STOCK_IMAGE_MIN_HEIGHT = 200;

export const STOCK_IMAGE_MIN_WIDTH = 722;

export const STOCK_IMAGE_INITIAL_SEARCH = 'textured+background';

export const RANDOM_EMOJI_LIST = [
  '📈',
  '📊',
  '📉',
  '📚',
  '📝',
  '📖',
  '📓',
  '📔',
  '📒',
  '📚',
  '📖',
  '🔖',
  '🔗',
  '📎',
  '📑',
  '📌',
  '📍',
  '🚩',
  '🏁',
  '🌈',
  '☠️',
  '📤',
  '📥',
  '📦',
  '📫',
  '📮',
  '🗳️',
  '🗄️',
  '🗃️',
  '🗂️',
];

export const DEFAULT_PALETTES: PaletteBoard = {
  blue: {} as LimitedPalette,
  green: {} as LimitedPalette,
  magenta: {} as LimitedPalette,
  neutral: {} as ExpandedPalette,
  orange: {} as LimitedPalette,
  purple: {} as LimitedPalette,
  red: {} as LimitedPalette,
  yellow: {} as LimitedPalette,
  brand: {} as LimitedPalette,
};

export const COLLABORATION_USER_COLORS: string[] = [
  'purple',
  'red',
  'blue',
  'orange',
  'green',
  'magenta',
].flatMap((color) => ['50', '70', '80'].map((shade) => `${color}[${shade}]`));
