import React, { ReactElement, ReactNode, createContext, useState } from 'react';

type Configs = { [key: string]: string };

type PrivateConfigsProviderProps = {
  children: ReactNode;
};

interface PrivateConfigsContext {
  configs: Configs;
  setConfigs: React.Dispatch<React.SetStateAction<Configs>>;
}

export const PrivateConfigsContext = createContext({} as PrivateConfigsContext);

const PrivateConfigsProvider = ({ children }: PrivateConfigsProviderProps): ReactElement => {
  const [configs, setConfigs] = useState<Configs>({});

  return (
    <PrivateConfigsContext.Provider value={{ configs, setConfigs }}>
      {children}
    </PrivateConfigsContext.Provider>
  );
};

export { PrivateConfigsProvider };
