import { embedMediaSize } from '../shared/constants/editor';

export enum EmbedExtensionName {
  EMBED_CARD = 'embedCard',
  FILE = 'file',
  IFRAME = 'iframe',
  IMAGE = 'image',
  VIDEO = 'video',
}

type ContentTypes =
  | { contentType?: 'media'; description?: string; imgUrl?: string; title?: string }
  | { contentType: 'file'; description?: never; imgUrl?: never; title: string };

type IframeAltViewAttrs = {
  src: string;
  width: number | null;
  height: number | null;
  allowfullscreen: boolean;
  contentUrl: string;
};

type EmbedCardAltViewAttrs = {
  contentUrl: string;
};

export type EmbedCardAttributes = {
  extensionName: EmbedExtensionName.EMBED_CARD;
  width?: never;
  size: embedMediaSize;
  contentUrl: string;
  altViewAttrs: IframeAltViewAttrs;
  alignment?: never;
} & ContentTypes;

export type FileAttributes = {
  contentUrl: string;
  fileName: string;
};

export type DimensionType = string | number | null;

export type IframeAttributes = {
  extensionName: EmbedExtensionName.IFRAME;
  src: string;
  width: DimensionType;
  height: DimensionType;
  allowfullscreen: boolean;
  size: embedMediaSize;
  scrolling: boolean;
  contentUrl: string;
  altViewAttrs: EmbedCardAltViewAttrs;
  alignment?: never;
  isCapture: boolean;
  className?: string;
};

export type Alignment = 'left' | 'center' | 'right';

export type ImageAttributes = {
  altText: string | undefined;
  extensionName: EmbedExtensionName.IMAGE;
  src: string;
  width: number | null;
  isBorder: boolean;
  isShadow: boolean;
  isRoundCorners: boolean;
  size?: never;
  altViewAttrs?: never;
  alignment: Alignment;
  link: string | null;
};
