import React from 'react';
import styled from 'styled-components';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';

const t = initTranslations('editor.embed_options_bar');

const TextWrapper = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const DeleteButton = () => {
  const { deleteNode } = useEditorEmbedOptionsContext();

  return (
    <button className='bubble-menu-delete-btn' onClick={deleteNode}>
      <Icon name='trash-alt' size='xs' />
      <TextWrapper>{t('delete')}</TextWrapper>
    </button>
  );
};

export default DeleteButton;
