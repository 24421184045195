import { DefaultTheme } from 'styled-components';

import { ButtonSize } from './iconButtonTypes';

export const getIconButtonStyles = (size: ButtonSize, theme: DefaultTheme) => {
  let styles = {};
  switch (size) {
    case 'sm':
    case 'md':
    case 'lg':
      styles = {
        padding: theme.constants.spacerSm3,
        'border-radius': theme.constants.borderRadiusMd,
        display: 'flex',
      };
      break;
  }

  return styles;
};
