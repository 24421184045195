import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DocumentImportLaunchLocation } from '../../../hooks/useAnalyticsUserEvents';

interface State {
  showDocumentImportModal: boolean;
  modalLaunchedFrom: DocumentImportLaunchLocation;
}

const initialState: State = {
  showDocumentImportModal: false,
  modalLaunchedFrom: 'universal_add',
};

const documentUploadSlice = createSlice({
  name: 'documentUploadSlice',
  initialState,
  reducers: {
    openDocumentImportModal(state, action: PayloadAction<DocumentImportLaunchLocation>) {
      state.showDocumentImportModal = true;
      state.modalLaunchedFrom = action.payload;
    },
    closeDocumentImportModal(state) {
      state.showDocumentImportModal = false;
    },
  },
});

export const { openDocumentImportModal, closeDocumentImportModal } = documentUploadSlice.actions;
export default documentUploadSlice.reducer;
