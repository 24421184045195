import { useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import InputField from '../../../../../design_system/Triage/InputField';
import { BubbleButton, BubbleFlyout, BubbleForm } from '../../../shared/styles';
import { urlValidationSchema } from '../../../shared/validators';
import { EmbedExtensionName } from '../../../tiptap_extensions/types';
import { MenuProps } from './shared/Dropdown';

const t = initTranslations('editor.embed_options_bar');

type Props = {
  hideOptionsBar: () => void;
} & MenuProps;

const IMG_LINK_MENU_ID = 'img_link_menu_id';

const ImageLinkButton = ({ hideOptionsBar, openMenuId, setOpenMenuId }: Props) => {
  const { currentAttributes, updateAttributes } = useEditorEmbedOptionsContext();
  const isLinkFieldVisible = useMemo(() => IMG_LINK_MENU_ID === openMenuId, [openMenuId]);

  const link =
    currentAttributes.extensionName === EmbedExtensionName.IMAGE ? currentAttributes.link : '';

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: { link: null },
    validationSchema: urlValidationSchema,
    onSubmit: ({ link }: { link: string | null }) => {
      updateAttributes({ link });
      setOpenMenuId(null);
    },
  });

  const handleClick = useCallback(() => {
    link
      ? updateAttributes({ link: null })
      : setOpenMenuId(isLinkFieldVisible ? null : IMG_LINK_MENU_ID);
  }, [link, updateAttributes, setOpenMenuId, isLinkFieldVisible]);

  if (currentAttributes.extensionName !== EmbedExtensionName.IMAGE) return <></>;

  return (
    <>
      <BubbleButton onClick={handleClick}>
        <Icon name='link' size='xs' />
        <span>{link ? t('unlink') : t('add_link')}</span>
      </BubbleButton>
      {isLinkFieldVisible && (
        <BubbleFlyout id='add-image-link-flyout'>
          <BubbleForm onSubmit={handleSubmit}>
            <InputField
              autoFocus
              errorText={errors.link}
              id='link'
              name='link'
              onBlur={() => handleSubmit()}
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !errors.link) {
                  hideOptionsBar();
                  handleSubmit();
                }
              }}
              placeholder={t('link_placeholder')}
              type='text'
              value={values.link || ''}
            />
          </BubbleForm>
        </BubbleFlyout>
      )}
    </>
  );
};

export default ImageLinkButton;
