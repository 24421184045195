import React, { useCallback } from 'react';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import { embedMediaSize } from '../../../shared/constants/editor';
import { EmbedOptionBarItemProps } from '../EmbedOptionsBar';
import Dropdown, { MenuOption } from './shared/Dropdown';

const t = initTranslations('editor.embed_options_bar');

const EMBED_SIZES: {
  size: embedMediaSize;
  text: string;
}[] = [
  { size: 'large', text: t('size.large') },
  { size: 'medium', text: t('size.medium') },
  { size: 'small', text: t('size.small') },
];

const SizeDropdown = ({ hideOptionsBar, openMenuId, setOpenMenuId }: EmbedOptionBarItemProps) => {
  const {
    updateAttributes,
    currentAttributes: { size: activeSize },
  } = useEditorEmbedOptionsContext();

  const activeIndex = Math.max(
    EMBED_SIZES.findIndex(({ size }) => size === activeSize),
    0
  );

  const updateSize = useCallback(
    (size: embedMediaSize) => {
      updateAttributes({ size });
      hideOptionsBar();
    },
    [hideOptionsBar, updateAttributes]
  );

  const options = EMBED_SIZES.map(({ size, text }) => {
    const isActive = size === activeSize;
    return (
      <MenuOption isActive={isActive} key={size} onClick={() => updateSize(size)}>
        {text}
      </MenuOption>
    );
  });

  return (
    <Dropdown
      buttonText={EMBED_SIZES[activeIndex].text}
      id='size-dropdown'
      openMenuId={openMenuId}
      options={options}
      setOpenMenuId={setOpenMenuId}
    />
  );
};

export default SizeDropdown;
