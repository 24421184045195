import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';

import {
  ImageType,
  ImageTypeAction,
  imageDataReducer,
  initialImageDataState,
} from '../components/application/editor/plugins/Image/imageDataReducer';
import { MAX_FILE_SIZE_MB } from '../components/application/editor/shared/constants/editor';
import usePrivateConfigs from '../hooks/usePrivateConfigs';
import { generateRandomFileName } from '../lib/generateRandomFilename';
import initTranslations from '../lib/initTranslations';
import { getEmbedlyDisplay } from '../redux/services/embedlyService';
import { UploadImageResponse } from '../redux/services/types';

const t = initTranslations('editor.image');

interface ImageFlyoutContext {
  fetchUrl: (url: string | null) => Promise<void>;
  processFile: (file: File) => void;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  isFetchingUrl: boolean;
  isDisabled: boolean;
  uploadImage: (image: FormData) => void;
  uploadResult: UploadImageResult;
  imageError: string;
  selectedImageUrl: string | null;
  imageFile: File | null;
  imageType: ImageType;
  imageDataDispatch: Dispatch<ImageTypeAction>;
  setImageError: Dispatch<SetStateAction<string>>;
  stockImageMinWidth?: number;
  stockImageMinHeight?: number;
  stockImageInitialSearch?: string;
}

type FlyoutProps = {
  children: ReactNode;
  uploadImage: (image: FormData) => void;
  uploadResult: UploadImageResult;
  stockImageMinWidth?: number;
  stockImageMinHeight?: number;
  stockImageInitialSearch?: string;
};

const ImageFlyoutContext = React.createContext({} as ImageFlyoutContext);
export const useImageFlyoutContext = () => useContext(ImageFlyoutContext);

export interface UploadImageResult {
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError | undefined;
  data?: UploadImageResponse | undefined;
  reset: () => void;
}

const ImageFlyoutProvider = ({
  children,
  uploadImage,
  uploadResult,
  stockImageMinWidth = 0,
  stockImageMinHeight = 0,
  stockImageInitialSearch = 'nature',
}: FlyoutProps): ReactElement => {
  const [{ selectedImageUrl, imageFile, imageType }, imageDataDispatch] = useReducer(
    imageDataReducer,
    initialImageDataState
  );

  const [submitting, setSubmitting] = useState(false);
  const [isFetchingUrl, setIsFetchingUrl] = useState(false);

  const isDisabled = useMemo(
    () => submitting || (!imageFile && !selectedImageUrl),
    [imageFile, selectedImageUrl, submitting]
  );

  const [imageError, setImageError] = useState<string>('');

  const { configs } = usePrivateConfigs();
  const apiKey = configs['EMBEDLY_KEY'];

  const processFile = useCallback((file: File) => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize >= MAX_FILE_SIZE_MB) {
      setImageError(t('error.file_size', { fileSizeMb: MAX_FILE_SIZE_MB }));
    } else {
      setImageError('');

      imageDataDispatch({ type: 'setImageFile', imageFile: file });
    }
  }, []);

  // fetchUrl is used for the following:
  // 1. Image from URL
  // 2. Giphy
  // 3. Stock Images
  const fetchUrl = useCallback(
    async (url: string) => {
      try {
        setIsFetchingUrl(true);
        const response = await getEmbedlyDisplay({ url, apiKey });

        if (response.ok) {
          const blob = await response.blob();
          const file = new File([blob], generateRandomFileName(), { type: blob.type });

          processFile(file);
          setIsFetchingUrl(false);
        } else {
          setImageError(t('error.generic'));
          setIsFetchingUrl(false);
        }
      } catch {
        setImageError(t('error.generic'));
      }
    },
    [apiKey, processFile]
  );

  return (
    <ImageFlyoutContext.Provider
      value={{
        isFetchingUrl,
        fetchUrl,
        imageError,
        isDisabled,
        processFile,
        setImageError,
        selectedImageUrl,
        imageFile,
        imageType,
        imageDataDispatch,
        setSubmitting,
        submitting,
        uploadImage,
        uploadResult,
        stockImageMinWidth,
        stockImageMinHeight,
        stockImageInitialSearch,
      }}
    >
      {children}
    </ImageFlyoutContext.Provider>
  );
};

export { ImageFlyoutProvider };
