import { Form, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';

import { useImageFlyoutContext } from '../../../../../contexts/ImageFlyoutContext';
import initTranslations from '../../../../../lib/initTranslations';
import InputField from '../../../../design_system/Triage/InputField';

const t = initTranslations('editor.image');

export type Values = {
  url: string;
};

const ImageForm = () => {
  const { setImageError, imageDataDispatch } = useImageFlyoutContext();
  const { errors, handleChange, setErrors, submitForm, touched } = useFormikContext<Values>();

  const debouncedSubmit = useMemo(
    () =>
      debounce(async () => {
        await submitForm();
      }, 500),
    [submitForm]
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    debouncedSubmit();
    imageDataDispatch({ type: 'resetImageData' });
    setImageError('');
    setErrors({});
  };

  return (
    <Form>
      <InputField
        autoFocus
        errorText={touched.url && errors.url}
        id='image-input-field'
        label={t('label')}
        name='url'
        onChange={onChange}
        placeholder={t('placeholder')}
        required
        type='url'
      />
    </Form>
  );
};

export default ImageForm;
