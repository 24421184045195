import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';

import { useImageFlyoutContext } from '../../../../../../contexts/ImageFlyoutContext';
import usePrivateConfigs from '../../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../../lib/initTranslations';
import { useGetImagesQuery } from '../../../../../../redux/services/pixabayApi/pixabayService';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import InputField from '../../../../../design_system/Triage/InputField';
import { EditorInputContainer } from '../../../shared/styles';
import StockImages from './StockImages';

const t = initTranslations('editor.image');

const StockImageInput = () => {
  const {
    imageDataDispatch,
    selectedImageUrl,
    imageError,
    setImageError,
    stockImageInitialSearch,
    stockImageMinHeight,
    stockImageMinWidth,
  } = useImageFlyoutContext();
  const [search, setSearch] = useState(stockImageInitialSearch);
  const { configs } = usePrivateConfigs();
  const pixabayApiKey = configs['PIXABAY_KEY'];

  const {
    data: images,
    isError,
    isLoading,
  } = useGetImagesQuery({
    apiKey: pixabayApiKey,
    ...(search && { q: search }),
    per_page: 50,
    min_width: stockImageMinWidth,
    min_height: stockImageMinHeight,
    page: 1,
  });

  const handleImageClick = useCallback(
    (url: string) => {
      if (selectedImageUrl === url) {
        imageDataDispatch({ type: 'resetImageData' });
      } else {
        imageDataDispatch({
          type: 'setImageUrl',
          selectedImageUrl: url,
          imageType: 'stock_image',
        });
      }
    },
    [imageDataDispatch, selectedImageUrl]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value) {
      setImageError('');
      imageDataDispatch({ type: 'resetImageData' });
    }

    debounce((value: string) => {
      setSearch(value);
    }, 500)(value);
  };

  return (
    <EditorInputContainer>
      <InputField
        data-testid='stock-image-input'
        id='stock-image-input'
        label={t('image_label')}
        onChange={handleChange}
        placeholder={t('stock_placeholder')}
      />
      <StockImages
        handleImageClick={handleImageClick}
        images={images}
        isError={isError}
        isLoading={isLoading}
        selectedImageUrl={selectedImageUrl}
      />
      {imageError && <AssistiveText id='assistive-text-error' text={imageError} type='error' />}
    </EditorInputContainer>
  );
};

export default StockImageInput;
