import autosize from '@github/textarea-autosize';
import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import AssistiveText from '../../../core/AssistiveText';
import FieldLabel from '../../../core/FieldLabel';

type NativeTextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type TextareaProps = Omit<NativeTextareaProps, 'ref' | 'style'> & {
  forwardRef?: React.RefObject<HTMLTextAreaElement>;
  errorText?: string | false;
};

const TextareaGroup = styled.div`
  width: 100%;
  border: ${({ theme: { vars } }) => vars.borderSurface2};
  display: flex;
  flex-direction: column;
`;

const Textarea = styled.textarea<{ error?: boolean }>`
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  color: ${({ theme: { vars } }) => vars.textDefault};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => `5px ${constants.spacerMd1}`};
  resize: vertical;
  ${(props) => (props.error ? `border-color: ${props.theme.vars.stateError};` : '')};

  &::placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  &:disabled {
    border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
      ${({ theme: { vars } }) => vars.borderDisabled};
    background: ${({ theme: { vars } }) => vars.foundationBase2};
    cursor: not-allowed;
  }

  &:focus {
    border-color: ${({ theme: { vars }, error }) => !error && vars.accentPrimaryDefault};
    outline: none;
  }
`;

export interface Props extends TextareaProps {
  label?: string;
  placeholder?: string;
  errorText?: string | false;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  assistiveText?: string;
}

const TextareaField = (props: Props) => {
  const { errorText, label, assistiveText, ...standardProps } = props;
  const name = standardProps.name || '';
  const id = standardProps.id || name;
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useLayoutEffect(() => {
    autosize(inputRef.current);
  }, []);

  return (
    <TextareaGroup>
      {label && <FieldLabel htmlFor={id} text={label} />}
      <Textarea {...standardProps} error={!!errorText} ref={inputRef} />
      {(errorText || assistiveText) && (
        <AssistiveText
          id={`input-${errorText ? 'error' : 'help'}-text`}
          text={errorText || assistiveText}
          type={errorText ? 'error' : 'help'}
        />
      )}
    </TextareaGroup>
  );
};

export default TextareaField;
