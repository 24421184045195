import { ExpandedPalette, PaletteBoard } from 'saguaro';

const HEX_REGEX = /^#([0-9a-f]{3}){1,2}$/i;

export const isHexColor = (color: string) => {
  return HEX_REGEX.test(color);
};

export const isRGBorRGBA = (str: string) => {
  return /^(rgb|rgba)\(\s*-?\d+%?\s*,\s*-?\d+%?\s*,\s*-?\d+%?\s*(,\s*-?\d+\.?\d+)?\)$/.test(str);
};

export const rgbToHex = (rgb: string) => {
  const match = rgb.match(/(\d+),\s*(\d+),\s*(\d+)/);
  if (!match) {
    return null;
  }
  const [, r, g, b] = match;
  return `#${(+r).toString(16).padStart(2, '0')}${(+g).toString(16).padStart(2, '0')}${(+b)
    .toString(16)
    .padStart(2, '0')}`;
};

export const paletteColorDecoder = (palettes: PaletteBoard, color: string) => {
  if (isHexColor(color)) {
    return color;
  }

  if (isRGBorRGBA(color)) {
    return rgbToHex(color) || color;
  }

  const matcher = color.match(/^(\w+)\[(\d+)]$/);

  let resultColor = color;

  if (matcher) {
    const colorName = matcher[1] as keyof PaletteBoard;
    const shade = matcher[2].padStart(2, '0');

    const palette = palettes[colorName];

    if (!!palette) {
      const paletteColor = (palette as ExpandedPalette)[shade as keyof ExpandedPalette];

      if (!!paletteColor) {
        resultColor = paletteColor;
      }
    }
  }

  return resultColor;
};

export const isActiveColor = (color: string, activeColor: string | undefined) => {
  return color === activeColor;
};
