import React from 'react';
import styled from 'styled-components';

import { ImageStyles } from './ImageResizeComponent';

const StyledImg = styled.img<ImageStyles>`
  max-width: 100%;
  position: relative;
  float: none;
  border: ${({ theme: { constants, vars }, isBorder }) =>
    isBorder && `${constants.borderWidth2xl} solid ${vars.borderDisabled}`};
  box-shadow: ${({ isShadow }) => isShadow && '0 2px 6px rgba(0, 0, 0, 0.25)'};
  border-radius: ${({ theme: { constants }, isRoundCorners }) =>
    isRoundCorners && constants.borderRadiusLg};
`;

type Props = {
  src: string;
  width: string;
  link: string | null;
} & ImageStyles;

const Image = (props: Props) => {
  const { alt, src, width, isBorder, isShadow, isRoundCorners, link } = props;

  const imgComponent = (
    <StyledImg
      alt={alt}
      className='editor-image'
      isBorder={isBorder}
      isRoundCorners={isRoundCorners}
      isShadow={isShadow}
      src={src}
      width={width}
    />
  );

  if (link)
    return (
      <a href={link} rel='noreferrer' target='_blank'>
        {imgComponent}
      </a>
    );

  return imgComponent;
};

export default Image;
