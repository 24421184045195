import { createApi } from '@reduxjs/toolkit/query/react';

import { toCamelCase } from '../../lib/keyFormatConverter';
import axiosBaseQuery from '../baseQueries/axiosBaseQuery';
import {
  UploadImageResponse,
  UploadStepAttachmentResponse,
  UploadSurveyQuestionAttachmentResponse,
} from './types';

export const axiosApi = createApi({
  reducerPath: 'axiosApi',
  baseQuery: axiosBaseQuery({ baseUrl: '', requireAccountSlug: true }),
  endpoints: (builder) => ({
    postStepImage: builder.mutation<UploadImageResponse, FormData>({
      query: (body) => ({
        url: 'ajax/uploads/step_image',
        method: 'POST',
        body,
      }),
    }),
    postStepAttachment: builder.mutation<UploadStepAttachmentResponse, FormData>({
      query: (body) => ({
        url: 'ajax/uploads/step_attachment',
        method: 'POST',
        body,
      }),
      transformResponse: (response: UploadStepAttachmentResponse) => {
        return toCamelCase(response);
      },
    }),
    postUserBioImage: builder.mutation<UploadImageResponse, { userId: number; image: FormData }>({
      query: (data) => ({
        url: `ajax/uploads/user_bio_image?user_id=${data.userId}`,
        method: 'POST',
        body: data.image,
      }),
    }),
    postSurveyQuestionAttachment: builder.mutation<
      UploadSurveyQuestionAttachmentResponse,
      FormData
    >({
      query: (body) => ({
        url: 'ajax/uploads/survey_question_attachment',
        method: 'POST',
        body,
      }),
      transformResponse: (response: UploadSurveyQuestionAttachmentResponse) => {
        return toCamelCase(response);
      },
    }),
    postSurveyQuestionImage: builder.mutation<UploadImageResponse, FormData>({
      query: (body) => ({
        url: 'ajax/uploads/survey_question_image',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  usePostStepImageMutation,
  usePostStepAttachmentMutation,
  usePostUserBioImageMutation,
  usePostSurveyQuestionAttachmentMutation,
  usePostSurveyQuestionImageMutation,
} = axiosApi;
