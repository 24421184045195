import React from 'react';
import { useTheme } from 'styled-components';

interface ThemedImage {
  lightImage: string;
  darkImage?: string;
  alt?: string;
  width?: string;
  height?: string;
  className?: string;
}

const ThemedImage = ({ lightImage, darkImage, alt, width, height, className }: ThemedImage) => {
  const { mode } = useTheme();

  return (
    <img
      alt={alt}
      className={className}
      height={height}
      src={mode === 'dark' && darkImage ? darkImage : lightImage}
      width={width}
    />
  );
};

export default ThemedImage;
