import React, { useCallback } from 'react';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import { MEDIA_SIZE_TO_WIDTH } from '../../../shared/constants/editor';
import { EmbedOptionBarItemProps } from '../EmbedOptionsBar';
import Dropdown, { MenuOption } from './shared/Dropdown';

const t = initTranslations('editor.embed_options_bar');

const WidthDropdown = ({ openMenuId, setOpenMenuId }: EmbedOptionBarItemProps) => {
  const EMBED_WIDTHS: {
    width: number;
    text: string;
  }[] = [
    { width: MEDIA_SIZE_TO_WIDTH.large, text: t('size.large') },
    { width: MEDIA_SIZE_TO_WIDTH.medium, text: t('size.medium') },
    { width: MEDIA_SIZE_TO_WIDTH.small, text: t('size.small') },
  ];

  const {
    updateAttributes,
    currentAttributes: { width: activeWidth },
  } = useEditorEmbedOptionsContext();

  const activeIndex = EMBED_WIDTHS.findIndex(({ width }) => width === activeWidth);

  const updateWidth = useCallback(
    (width: number) => {
      updateAttributes({ width });
    },
    [updateAttributes]
  );

  const options = EMBED_WIDTHS.map(({ width, text }) => {
    const isActive = width === activeWidth;
    return (
      <MenuOption isActive={isActive} key={width} onClick={() => updateWidth(width)}>
        {text}
      </MenuOption>
    );
  });

  return (
    <Dropdown
      buttonText={activeIndex >= 0 ? EMBED_WIDTHS[activeIndex].text : t('size.unselected')}
      id='width-dropdown'
      openMenuId={openMenuId}
      options={options}
      setOpenMenuId={setOpenMenuId}
    />
  );
};

export default WidthDropdown;
