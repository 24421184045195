import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  BoardColumn as TBoardColumn,
  TCurrentBoardColumnResponsibility,
} from '../../../components/application/DelegationPlanner/BoardColumn/types';

type TCurrentDragArgs = {
  id: string;
  position: number;
  sourceColumnId: number;
  targetColumnId: number;
};

interface State {
  groupsModalSearchTerm: string;
  selectedGroupIds: number[];
  selectedUserIds: number[];
  arePillsExpanded: boolean;
  currentBoardColumnResponsibility: TCurrentBoardColumnResponsibility;
  currentColumnId: number | null;
  currentDragArgs: TCurrentDragArgs | null;
  shouldScrollToCurrentResponsibility: boolean;
  lastAddedColumnableId: number | null;
  shouldScrollBoardLeft: boolean;
  targetColumnName: string;
  isFullscreenModeEnabled: boolean;
  tempColumns: TBoardColumn[];
}

const initialState: State = {
  groupsModalSearchTerm: '',
  selectedGroupIds: [],
  arePillsExpanded: false,
  selectedUserIds: [],
  currentBoardColumnResponsibility: {} as TCurrentBoardColumnResponsibility,
  currentColumnId: null,
  currentDragArgs: null,
  shouldScrollToCurrentResponsibility: false,
  lastAddedColumnableId: null,
  shouldScrollBoardLeft: false,
  targetColumnName: '',
  isFullscreenModeEnabled: false,
  tempColumns: [],
};

const delegationPlannerSlice = createSlice({
  name: 'delegationPlannerSlice',
  initialState,
  reducers: {
    setGroupsModalSearchTerm(state, action: PayloadAction<string>) {
      state.groupsModalSearchTerm = action.payload;
    },
    addSelectedGroupId(state, action: PayloadAction<number>) {
      if (!state.selectedGroupIds.includes(action.payload)) {
        state.selectedGroupIds.push(action.payload);
      }
    },
    removeSelectedGroupId(state, action: PayloadAction<number>) {
      state.selectedGroupIds = state.selectedGroupIds.filter((id) => id !== action.payload);
    },
    resetSelectedGroupIds(state) {
      state.selectedGroupIds = [];
    },
    addSelectedUserId(state, action: PayloadAction<number>) {
      if (!state.selectedUserIds.includes(action.payload)) {
        state.selectedUserIds.push(action.payload);
      }
    },
    removeSelectedUserId(state, action: PayloadAction<number>) {
      state.selectedUserIds = state.selectedUserIds.filter((id) => id !== action.payload);
    },
    resetSelectedUserIds(state) {
      state.selectedUserIds = [];
    },
    togglePills(state) {
      state.arePillsExpanded = !state.arePillsExpanded;
    },
    setCurrentResponsibility(state, action: PayloadAction<TCurrentBoardColumnResponsibility>) {
      state.currentBoardColumnResponsibility = action.payload;
    },
    setCurrentColumnId(state, action: PayloadAction<number | null>) {
      state.currentColumnId = action.payload;
    },
    setCurrentDragArgs(state, action: PayloadAction<TCurrentDragArgs | null>) {
      state.currentDragArgs = action.payload;
    },
    setShouldScrollToCurrentResponsibility(state, action: PayloadAction<boolean>) {
      state.shouldScrollToCurrentResponsibility = action.payload;
    },
    setIsCurrentResponsibilityPositionChanged(state, action: PayloadAction<boolean>) {
      state.currentBoardColumnResponsibility.isPositionChanged = action.payload;
    },
    setLastAddedColumnableId(state, action: PayloadAction<number | null>) {
      state.lastAddedColumnableId = action.payload;
    },
    setShouldScrollBoardLeft(state, action: PayloadAction<boolean>) {
      state.shouldScrollBoardLeft = action.payload;
    },
    setTargetColumnName(state, action: PayloadAction<string>) {
      state.targetColumnName = action.payload;
    },
    setTempColumns(state, action: PayloadAction<TBoardColumn[]>) {
      state.tempColumns = action.payload;
    },
    toggleFullscreenMode(state) {
      state.isFullscreenModeEnabled = !state.isFullscreenModeEnabled;
    },
  },
});

export const {
  setGroupsModalSearchTerm,
  addSelectedGroupId,
  removeSelectedGroupId,
  resetSelectedGroupIds,
  addSelectedUserId,
  removeSelectedUserId,
  resetSelectedUserIds,
  togglePills,
  setCurrentResponsibility,
  setCurrentColumnId,
  setCurrentDragArgs,
  setShouldScrollToCurrentResponsibility,
  setIsCurrentResponsibilityPositionChanged,
  setLastAddedColumnableId,
  setShouldScrollBoardLeft,
  setTargetColumnName,
  setTempColumns,
  toggleFullscreenMode,
} = delegationPlannerSlice.actions;
export default delegationPlannerSlice.reducer;
