import React from 'react';
import styled from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';

const StyledP = styled.p`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: 0;
  margin-top: 0;
  ${fontSm5};
`;

export interface Props {
  id?: string;
  text: string | React.ReactNode;
  className?: string;
}

const P = ({ id, text, className }: Props) => {
  return (
    <StyledP className={className} id={id}>
      {text}
    </StyledP>
  );
};

export default P;
