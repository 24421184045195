import Paragraph from '@tiptap/extension-paragraph';
import { Plugin, PluginKey } from '@tiptap/pm/state';

// Selectors that we want to skip in the extension
const skippedSelectors = ['img', 'i.fa', 'a.fr-file', 'span.fr-video', 'iframe'];

const shouldSkipNode = (node: HTMLElement) => {
  return !skippedSelectors.some((selector) => node.querySelector(selector));
};

export default Paragraph.extend({
  parseHTML() {
    return [
      {
        tag: 'p',
        getAttrs: (node) => {
          if (node instanceof HTMLElement) {
            if (!shouldSkipNode(node)) {
              return false;
            }

            return {}; // For nodes you don't want to skip, return an empty object or specific attributes.
          }

          return false; // In case the node isn't an HTMLElement for some reason, we skip it.
        },
      },
    ];
  },
  addAttributes() {
    return {
      class: {},
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('trailingLingBreakHandler'),
        appendTransaction: (_transactions, _oldState, newState) => {
          const tr = newState.tr;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            if (node.type.name === 'paragraph') {
              let allChildrenAreBreaks = true;
              let count = 0;

              node.forEach((child) => {
                if (child.type.name === 'hardBreak') {
                  count++;
                } else {
                  allChildrenAreBreaks = false;
                  return;
                }
              });

              if (allChildrenAreBreaks && count > 1) {
                modified = true;
                tr.setNodeMarkup(pos, undefined, { class: 'empty-trailingbreaks' });
              }
            }
          });

          return modified ? tr : null;
        },
      }),
    ];
  },
});
