import Image from '@tiptap/extension-image';
import { ReactNodeViewRenderer } from '@tiptap/react';

import ImageResizeComponent from '../resizable_image/ImageResizeComponent';
import { EmbedExtensionName } from '../types';

const LEFT_ALIGN_CLASSES = ['fr-dib fr-fil', 'fr-dii fr-fil'];
const RIGHT_ALIGN_CLASSES = ['fr-dii fr-fir', 'fr-dib fr-fir'];
const CENTER_ALIGN_CLASSES = ['fr-fic', 'fr-dib'];

// This function finds the first ancestor of an element that has any of the classes in the classArray.
// It returns null if there is no such ancestor.
const findParentWithClasses = (element: Element | null, classArray: string[]): Element | null => {
  while (element) {
    const classes = Array.from(element.classList);
    if (classes.some((className) => classArray.includes(className))) {
      return element;
    }
    element = element.parentElement;
  }
  return null;
};

// This function checks if the element has any of the target classes,
// or if any of its parents have any of the target classes.
const extractStyleFromClassOrParents = (
  element: HTMLElement | null,
  targetClasses: string[]
): boolean => {
  const parentElementWithStyle = findParentWithClasses(element, targetClasses);
  return parentElementWithStyle !== null;
};

// Extracts the link of an image from its parent element, or return null if
// there is no link.
const extractLinkFromParentElement = (
  element: HTMLElement | null,
  stopAtClass?: string
): string | null => {
  element = element?.parentElement as HTMLElement | null;

  while (element) {
    if (element instanceof HTMLAnchorElement && element.href) {
      return element.href;
    }

    if (stopAtClass && element.classList.contains(stopAtClass)) {
      break;
    }
    element = element.parentElement as HTMLElement | null;
  }

  return null;
};

// Extracts the width of an image from its width property, or if that's not
// defined, from its style width property. If neither of those are defined,
// this function looks at the image's parents for the same properties. If it
// finds a value, it returns it. Otherwise, it returns null.
const extractWidthFromElementOrParents = (element: HTMLElement | null): number | null => {
  while (element) {
    if (element instanceof HTMLImageElement && element.width) {
      return element.width;
    }

    const styleWidth = element.style.width;
    if (styleWidth) {
      // Width is a percentage and if the element is inside a table return null.
      if (styleWidth.endsWith('%') && element.closest('table')) {
        return null;
      } else if (!isNaN(parseInt(styleWidth))) {
        // Width is not a percentage, return the numeric value.
        return parseInt(styleWidth);
      }
    }

    element = element.parentElement as HTMLElement | null;
  }

  return null;
};

// This code parses a string of HTML and returns the src attribute of the first img element it finds.
const parseHTML = (element: HTMLElement | string) => {
  try {
    let imgElement;

    if (typeof element === 'string') {
      const parser = new DOMParser();
      const doc = parser.parseFromString(element, 'text/html');
      imgElement = doc.body.querySelector('img');
    } else if (element instanceof HTMLElement) {
      imgElement = element;
    }

    return imgElement
      ? {
          src: imgElement.getAttribute('src'),
        }
      : {};
  } catch (e) {
    console.log('Error in parseHTML:', e);
    return {};
  }
};

export default Image.extend({
  parseHTML() {
    return [
      {
        tag: '.fr-img-wrap>img', // Froala with caption
        getAttrs: (dom) => parseHTML(dom),
      },
      {
        tag: 'p>img', // Froala inline
        getAttrs: (dom) => parseHTML(dom),
      },
      {
        tag: 'img[src]:not([src^="data:"])', // TipTap image
        getAttrs: (dom) => parseHTML(dom),
      },
    ];
  },
  addAttributes() {
    return {
      altText: {
        default: null,
        parseHTML: (element: HTMLImageElement) => {
          return element.alt;
        },
      },
      alignment: {
        default: 'center',
        parseHTML: (element) => {
          let alignment = 'center';
          const targetClasses = [
            ...LEFT_ALIGN_CLASSES,
            ...RIGHT_ALIGN_CLASSES,
            ...CENTER_ALIGN_CLASSES,
          ].reduce<string[]>((acc, val) => acc.concat(val), []);

          const parentElementWithAlignment = findParentWithClasses(element, targetClasses);
          const classes = parentElementWithAlignment
            ? Array.from(parentElementWithAlignment.classList)
            : [];

          LEFT_ALIGN_CLASSES.forEach((classNames) => {
            const alignClasses = classNames.split(' ');
            if (alignClasses.every((className) => classes.includes(className))) {
              alignment = 'left';
            }
          });

          RIGHT_ALIGN_CLASSES.forEach((classNames) => {
            const alignClasses = classNames.split(' ');
            if (alignClasses.every((className) => classes.includes(className))) {
              alignment = 'right';
            }
          });

          CENTER_ALIGN_CLASSES.forEach((classNames) => {
            const alignClasses = classNames.split(' ');
            if (
              alignment !== 'left' &&
              alignment !== 'right' &&
              alignClasses.every((className) => classes.includes(className))
            ) {
              alignment = 'center';
            }
          });

          return alignment;
        },
      },
      extensionName: {
        default: EmbedExtensionName.IMAGE,
      },
      isBorder: {
        default: false,
        parseHTML: (element: HTMLImageElement) => {
          return extractStyleFromClassOrParents(element, ['fr-bordered']);
        },
      },
      isRoundCorners: {
        default: false,
        parseHTML: (element: HTMLImageElement) => {
          return extractStyleFromClassOrParents(element, ['fr-rounded']);
        },
      },
      isShadow: {
        default: false,
        parseHTML: (element: HTMLImageElement) => {
          return extractStyleFromClassOrParents(element, ['fr-shadow']);
        },
      },
      src: {
        default: null,
      },
      width: {
        default: null,
        parseHTML: (element: HTMLImageElement) => {
          return extractWidthFromElementOrParents(element);
        },
      },
      link: {
        default: null,
        parseHTML: (element: HTMLImageElement) => {
          return extractLinkFromParentElement(element, 'editor-image-wrapper');
        },
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(ImageResizeComponent);
  },
});
