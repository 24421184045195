import React from 'react';
import styled from 'styled-components';

import { useEditorEmbedOptionsContext } from '../../../../../../contexts/EditorEmbedOptionsContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle/Toggle';
import { EmbedExtensionName } from '../../../tiptap_extensions/types';
import Dropdown, { MenuOption, MenuProps } from './shared/Dropdown';

const t = initTranslations('editor.embed_options_bar');

const StyledMenuOption = styled(MenuOption)`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const StylesDropdown = ({ openMenuId, setOpenMenuId }: MenuProps) => {
  const { currentAttributes, updateAttributes } = useEditorEmbedOptionsContext();

  if (currentAttributes.extensionName !== EmbedExtensionName.IMAGE) return <></>;

  const { isBorder, isShadow, isRoundCorners } = currentAttributes;

  const styles: {
    id: string;
    text: string;
    checked: boolean;
    handleToggle: () => void;
  }[] = [
    {
      id: 'border',
      text: t('styles.border'),
      checked: isBorder,
      handleToggle: () => {
        updateAttributes({ isBorder: !isBorder });
      },
    },
    {
      id: 'shadow',
      text: t('styles.shadow'),
      checked: isShadow,
      handleToggle: () => {
        updateAttributes({ isShadow: !isShadow });
      },
    },
    {
      id: 'round-corners',
      text: t('styles.round_corners'),
      checked: isRoundCorners,
      handleToggle: () => {
        updateAttributes({ isRoundCorners: !isRoundCorners });
      },
    },
  ];

  const options = styles.map(({ id, text, checked, handleToggle }) => {
    return (
      // Use stopPropagation to prevent Dropdown onClick from closing menu
      <StyledMenuOption key={id} onClick={(event) => event.stopPropagation()}>
        <span>{text}</span>
        <Toggle
          ariaLabel={text}
          checked={checked}
          dataTestId={id}
          handleToggle={handleToggle}
          id={id}
          name={id}
        />
      </StyledMenuOption>
    );
  });

  return (
    <Dropdown
      buttonText={t('styles.styles')}
      id='style-dropdown'
      menuWidth='wide'
      openMenuId={openMenuId}
      options={options}
      setOpenMenuId={setOpenMenuId}
    />
  );
};

export default StylesDropdown;
